import React from 'react';
// import classNames from 'classnames';
import { message } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import { useTranslation } from 'react-i18next';
// import { QuestionCircleOutlined } from '@ant-design/icons';

import { useWallet } from '../../../store/wallet-context';
import CustomButton from '../../UI/CustomButton/CustomButton';
import HashLinkWithQuery from '../../HashLinkWithQuery/HashLinkWithQuery';
// import CustomModal from '../../UI/CustomModal/CustomModal';
import {
  // useActivate,
  useCalcClaim,
  useClaim,
  useGetUnlockInfo,
  // useJudgeUnlockInfo,
  // useNeedToSync,
  // useSyncUnlock,
  // useZbcSupplyForUnlock,
} from '../../../hooks/useManager';
import { formatNumber } from '../../../utils/utils';

import styles from './RewardsPlan.module.scss';
import commonStyles from '../../../common.module.scss';
import { useAllTokenBalance } from '../../../hooks/useToken';
// import { useZBCPrice } from '../../../hooks/useZBCPrice';
import CustomTooltip from '../../UI/CustomTooltip/CustomTooltip';
import { useTimer } from '../../../hooks/useTimer';

const RewardsPlan = () => {
  const { t } = useTranslation();
  const { account } = useWallet();

  const stopMockCountdown = useTimer({
    deadline: new Date('2023-09-17T04:00:00+08:00'),
  });

  const switchToNaut = stopMockCountdown.timeIsDue;

  // const [claimVisible, setClaimVisible] = useState(false);
  // const zbcPrice = useZBCPrice();

  // unlockInfo for current level
  const { data: unlockInfo } = useGetUnlockInfo();
  // need to sync after activation
  // const { data: needToSync } = useNeedToSync();
  // estimated unlock info based on current purchase info
  // const { data: estimatedUnlockInfo } = useJudgeUnlockInfo();
  // get claimable
  const { data: claimable } = useCalcClaim();
  // get zbc left
  // const { data: zbcSupplyForUnlock } = useZbcSupplyForUnlock();
  // console.log(formatUnits(zbcSupplyForUnlock ?? '0', 9));

  // const { state: activateState, activate } = useActivate();
  // const { state: syncUnlockState, syncUnlock } = useSyncUnlock();
  const { state: claimState, claim } = useClaim();

  const allTokenBalance = useAllTokenBalance();
  const { data: nativeBalance } = allTokenBalance.nativeBalance;

  // const toggleClaimVisible = () => {
  //   setClaimVisible((prev) => !prev);
  // };

  // const handleActivate = async () => {
  //   if (
  //     activateState.isLoading ||
  //     Object.keys(zbcPrice).length === 0 ||
  //     !unlockInfo.level ||
  //     !estimatedUnlockInfo.level
  //   ) {
  //     return;
  //   }
  //   if (!account) {
  //     message.info(t('Please connect to wallet', 'Please connect to wallet'));
  //     return;
  //   }
  //   if (!unlockInfo.level.isZero()) {
  //     message.info(t('Already activated', 'Already activated'));
  //     return;
  //   }
  //   if (estimatedUnlockInfo.level?.isZero()) {
  //     message.info(t('Not eligible to activate', 'Not eligible to activate'));
  //     return;
  //   }
  //   if (nativeBalance < 0.01) {
  //     message.info(
  //       t(
  //         "You don't have enough BNB for gas to complete the transaction",
  //         "You don't have enough BNB for gas to complete the transaction",
  //       ),
  //     );
  //     return;
  //   }
  //   try {
  //     await activate(zbcPrice);
  //   } catch (error) {
  //     console.log('activate failed');
  //     console.log(error);
  //   }
  // };

  // const handleSync = async () => {
  //   if (syncUnlockState.isLoading) {
  //     return;
  //   }
  //   if (!account) {
  //     message.info(t('Please connect to wallet', 'Please connect to wallet'));
  //     return;
  //   }
  //   if (!needToSync) {
  //     message.info(t('Not eligible to update', 'Not eligible to update'));
  //     return;
  //   }
  //   if (nativeBalance < 0.01) {
  //     message.info(
  //       t(
  //         "You don't have enough BNB for gas to complete the transaction",
  //         "You don't have enough BNB for gas to complete the transaction",
  //       ),
  //     );
  //     return;
  //   }
  //   try {
  //     await syncUnlock(account, zbcPrice);
  //   } catch (error) {
  //     console.log('sync failed');
  //     console.log(error);
  //   }
  // };

  const handleClaim = async () => {
    if (claimState.isLoading) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (claimable.isZero()) {
      message.info(t('Nothing to claim', 'Nothing to claim'));
      return;
    }
    if (nativeBalance < 0.01) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    try {
      await claim();
    } catch (error) {
      console.log('claim failed');
    }
  };

  return (
    <div className={styles.RewardsPlan}>
      <div className={styles.planWrap}>
        <div className={styles.planCard}>
          <div className={styles.infoWrap}>
            <h2 className={commonStyles.gradientTitle}>{t('Current Yield', 'Current Yield')}</h2>
            <p>
              {formatNumber(unlockInfo.apy?.toNumber())}
              {unlockInfo.apy?.toNumber() ? '%' : ''}
            </p>
          </div>
          <div className={styles.actionWrap}>
            <HashLinkWithQuery to="/participate">
              <CustomButton secondary rounded className={styles.linkButton}>
                {t('Get Zepoch', 'Get Zepoch')}
              </CustomButton>
            </HashLinkWithQuery>
            <HashLinkWithQuery to="/profile#referral">
              <CustomButton secondary rounded className={styles.linkButton}>
                {t('Invite Friends', 'Invite Friends')}
              </CustomButton>
            </HashLinkWithQuery>
          </div>
        </div>

        <div className={styles.planCard}>
          <div className={styles.claimModalContent}>
            <div className={styles.claimModalInfoItem}>
              <h2>
                {t('Claimable', 'Claimable')}
                {switchToNaut ? ' NAUT' : ' ZBC'}
              </h2>
              <p>
                {formatNumber(formatUnits(claimable ?? '0', switchToNaut ? 18 : 9))}
                <span>{switchToNaut ? ' NAUT' : ' ZBC'}</span>
              </p>
            </div>
            <div className={styles.claimModalInfoItem}>
              <h2>
                {t('Claimable Bonus', 'Claimable Bonus')}
                {switchToNaut ? ' NAUT' : ' ZBC'}
                <CustomTooltip
                  title={
                    <>
                      <p>
                        {t(
                          'reward-plan-bonus-1',
                          'Earn 5% of your referrals rewards if number (owned zepoch + referred zepoch ) >= 5',
                        )}
                      </p>
                      <br />
                      <p>
                        {t(
                          'reward-plan-bonus-2',
                          'Earn 15% of your referrals refers’ rewards if number (owned zepoch + referred zepoch ) >= 15',
                        )}
                      </p>
                    </>
                  }>
                  {' '}
                  <img src="/static/images/Rewards/questionMark.svg" alt="mark" />
                </CustomTooltip>
              </h2>
              <p>
                {formatNumber(
                  formatUnits(unlockInfo.zbcBonusClaimable ?? '0', switchToNaut ? 9 : 9),
                )}
                <span>{switchToNaut ? ' NAUT' : ' ZBC'}</span>
              </p>
            </div>
            <div className={styles.claimModalInfoItem}>
              <h2 className={commonStyles.gradientTitle}>
                {t('Total Locked', 'Total Locked')}
                {switchToNaut ? ' NAUT' : ' ZBC'}
              </h2>
              <p>
                {formatNumber(formatUnits(unlockInfo.zbcPlaned ?? '0', switchToNaut ? 9 : 9))}
                <span>{switchToNaut ? ' NAUT' : ' ZBC'}</span>
              </p>
            </div>
            <div className={styles.claimModalInfoItem}>
              <h2 className={commonStyles.gradientTitle}>
                {t('Total Claimed', 'Total Claimed')}
                {switchToNaut ? ' NAUT' : ' ZBC'}
              </h2>
              <p>
                {formatNumber(
                  formatUnits(
                    unlockInfo.zbcClaimedCumulative?.add(unlockInfo.zbcBonusClaimedCumulative) ??
                      '0',
                    switchToNaut ? 9 : 9,
                  ),
                )}
                <span>{switchToNaut ? ' NAUT' : ' ZBC'}</span>
              </p>
            </div>

            <p className={styles.desc}>
              {t(
                'reward-plan-desc',
                'ZBC rewards will be vested in 24 month. You can withdraw every second.',
              )}
            </p>
            <CustomButton
              className={styles.linkButton}
              rounded
              loading={claimState.isLoading}
              onClick={handleClaim}>
              {t('Claim', 'Claim')}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsPlan;
