import { useCallback, useMemo, useReducer } from 'react';
import { Contract, ethers } from 'ethers';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';
import { useWallet } from '../store/wallet-context';

import { ADDRESSES, CONTRACT_NAME, TOKEN_POOL_INDEX } from '../constants/addresses';
import { useNameservice } from '../store/nameservice-context';

import HERC20Interface from '../abis/HERC20Interface.json';
import HERC721IMInterface from '../abis/HERC721IMInterface.json';
import InvitationCenterInterface from '../abis/InvitationCenterInterface.json';
import ZHManagerInterface from '../abis/ZHManagerInterface.json';
import TokenPoolInterface from '../abis/TokenPoolInterface.json';
import LinearUnlockInterface from '../abis/LinearUnlockInterface.json';
import MULTICALL_ABI from '../abis/multicall.json';
import { useIsMounted } from './useIsMounted';
import { FETCH_STATE, readContractReducer } from '../utils/utils';

export const useMulticallContract = () => {
  const { ethersProvider } = useWallet();
  return useMemo(() => {
    if (!ethersProvider) {
      return null;
    }
    return new Contract(ADDRESSES.MULTICALL, MULTICALL_ABI.abi, ethersProvider);
  }, [ethersProvider]);
};

export const useManagerContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.Manager]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, ZHManagerInterface.abi, ethersProvider.getSigner())
      : new Contract(addr, ZHManagerInterface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useInvitationCenterContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.InvitationCenter]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, InvitationCenterInterface.abi, ethersProvider.getSigner())
      : new Contract(addr, InvitationCenterInterface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useThemisGavelContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.ThemisGavel]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, HERC721IMInterface.abi, ethersProvider.getSigner())
      : new Contract(addr, HERC721IMInterface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useZepochNodeContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.ZepochNode]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, HERC721IMInterface.abi, ethersProvider.getSigner())
      : new Contract(addr, HERC721IMInterface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useERC20Contract = (address) => {
  const { ethersProvider, account } = useWallet();
  return useMemo(() => {
    if (!(ethersProvider && ethers.utils.isAddress(address))) {
      return null;
    }
    return account
      ? new Contract(address, HERC20Interface.abi, ethersProvider.getSigner())
      : new Contract(address, HERC20Interface.abi, ethersProvider);
  }, [ethersProvider, address, account]);
};

export const useZPointContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.ZPoint]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, HERC20Interface.abi, ethersProvider.getSigner())
      : new Contract(addr, HERC20Interface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useTokenPoolContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.Pool]: addrList } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addrList?.length)) {
      return null;
    }
    return account
      ? new Contract(addrList[TOKEN_POOL_INDEX], TokenPoolInterface.abi, ethersProvider.getSigner())
      : new Contract(addrList[TOKEN_POOL_INDEX], TokenPoolInterface.abi, ethersProvider);
  }, [account, ethersProvider, addrList]);
};

export const useLinearUnlockContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.LinearUnlock]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, LinearUnlockInterface.abi, ethersProvider.getSigner())
      : new Contract(addr, LinearUnlockInterface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useContract = (contract, methodName, decoder = null, initValue = null) => {
  const [state, dispatch] = useReducer(readContractReducer, {
    isLoading: false,
    isError: false,
    data: initValue,
  });
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const read = useCallback(
    async (args = [], overrides = {}) => {
      try {
        if (!(contract && methodName && args instanceof Array)) {
          return;
        }
        dispatch({ type: FETCH_STATE.INIT });
        const data = await contract[methodName](...args, overrides);
        if (isMounted.current) {
          dispatch({ type: FETCH_STATE.SUCCESS, payload: decoder ? decoder(data) : data });
        }
      } catch (error) {
        console.log(error);
        if (isMounted.current) {
          dispatch({ type: FETCH_STATE.FAILURE });
        }
      }
    },
    [contract, isMounted, methodName, decoder],
  );

  const write = useCallback(
    async (args = [], overrides = {}) => {
      try {
        if (!(contract && methodName)) {
          return;
        }
        dispatch({ type: FETCH_STATE.INIT });
        const tx = await contract[methodName](...args, overrides);
        message.info(t('Request sent', 'Request sent'));
        console.log('tx', tx);
        const receipt = await tx.wait();
        message.success(t('Transaction completed', 'Transaction completed'));
        console.log('receipt', receipt);
        if (isMounted.current) {
          dispatch({ type: FETCH_STATE.SUCCESS, payload: null });
        }
      } catch (error) {
        console.log(error);
        message.error(t('Transaction failed', 'Transaction failed'));
        if (isMounted.current) {
          dispatch({ type: FETCH_STATE.FAILURE });
        }
        throw error;
      }
    },
    [contract, isMounted, methodName, t],
  );

  return { state, read, write };
};
