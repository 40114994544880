import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import { useIsMounted } from './useIsMounted';
import { useWallet } from '../store/wallet-context';

const NAUTLIUS_GAS_SHARE_API = 'https://api2.zebec.io/api2/gasShare?address=';

const useGetGasShareAmount = (addr = ethers.constants.AddressZero) => {
  const [gasShareInfo, setGasShareInfo] = useState({});
  const { account } = useWallet();
  const isMounted = useIsMounted();
  useEffect(() => {
    const fetch = async () => {
      try {
        const apiCall = `${NAUTLIUS_GAS_SHARE_API}${account || addr}`;

        const res = await axios.get(apiCall);
        if (res.status !== 200) {
          throw new Error('Error read amount');
        }

        const { totalAmount, personalAmount, increment, personalIncrement } = res.data;
        const totalAmountInValue = ethers.utils.formatEther(totalAmount);
        const personalAmountInValue = ethers.utils.formatEther(personalAmount);
        const incrementInValue = ethers.utils.formatEther(increment);
        const personalIncrementInValue = ethers.utils.formatEther(personalIncrement);

        if (totalAmountInValue > 0 && isMounted) {
          setGasShareInfo({
            totalAmountInValue,
            personalAmountInValue,
            incrementInValue,
            personalIncrementInValue,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (account || addr) fetch();
  }, [account, addr, isMounted]);

  return gasShareInfo;
};

export default useGetGasShareAmount;
