import React, {
  useLayoutEffect,
  useState,
  // useEffect
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col } from 'antd';
import classNames from 'classnames';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import commonStyles from '../../common.module.scss';
import TitleCombo from '../../components/UI/TitleCombo/TitleCombo';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import ProgressiveVideo from '../../components/UI/ProgressiveVideo/ProgressiveVideo';
// import { useCalcPhase2Cost, usePhase1Config } from '../../hooks/useManager';
// import {
//   useVaultBalance,
//   useZepochNodeTotalSupply,
//   useZPointTotalSupply,
// } from '../../hooks/useToken';
// import { formatNumber } from '../../utils/utils';
// import { useCurrentPhase } from '../../hooks/useCurrentPhase';
import NavLinkWithQuery from '../../components/NavLinkWithQuery/NavLinkWithQuery';

import styles from './About.module.scss';

// const STATS_BREAK_POINTS = {
//   PHASE1: { md: 8 },
//   PHASE2: { md: 12, lg: 12, xl: 6 },
// };

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const { data: phase1Config } = usePhase1Config();
  const { t } = useTranslation();
  // const { data: zPointTotalSupply } = useZPointTotalSupply();
  // const { data: zepochNodeTotalSupply } = useZepochNodeTotalSupply();
  // const { data: phase2Cost } = useCalcPhase2Cost(1);
  // const currentPhase = useCurrentPhase();
  // const {
  //   data: { combinedBalance },
  // } = useVaultBalance();
  const [stats] = useState([]);

  // const breakPoints = currentPhase === 1 ? STATS_BREAK_POINTS.PHASE1 : STATS_BREAK_POINTS.PHASE2;

  // useEffect(() => {
  //   if (currentPhase === 1) {
  //     setStats([
  //       {
  //         id: 0,
  //         text: 'Zepoch Registered',
  //         img: 'Node',
  //         value: formatNumber(zepochNodeTotalSupply),
  //       },
  //       {
  //         id: 1,
  //         text: 'ZBC Staked',
  //         img: 'Zebec',
  //         value: formatNumber(phase1Config?.phase1ZbcTotalLocked),
  //       },
  //       {
  //         id: 2,
  //         text: 'Zepoch Price',
  //         img: 'Price',
  //         value: formatNumber(phase1Config?.phase1SellPrice, '$0,0[.]00'),
  //       },
  //       {
  //         id: 3,
  //         text: 'Zebec Points',
  //         img: 'Points',
  //         value: formatNumber(zPointTotalSupply),
  //       },
  //     ]);
  //   }
  //   if (currentPhase === 2) {
  //     setStats([
  //       {
  //         id: 0,
  //         text: 'Zepoch Registered',
  //         img: 'Node',
  //         value: formatNumber(zepochNodeTotalSupply),
  //       },
  //       { id: 1, text: 'Zepoch Price', img: 'Price', value: formatNumber(phase2Cost, '$0,0[.]00') },
  //       {
  //         id: 2,
  //         text: 'Zebec Points',
  //         img: 'Points',
  //         value: formatNumber(zPointTotalSupply),
  //       },
  //       {
  //         id: 3,
  //         text: 'Horizon Treasury',
  //         img: 'Treasury',
  //         value: formatNumber(combinedBalance, '$0,0[.]00'),
  //       },
  //     ]);
  //   }
  // }, [
  //   currentPhase,
  //   zepochNodeTotalSupply,
  //   phase1Config?.phase1ZbcTotalLocked,
  //   zPointTotalSupply,
  //   phase2Cost,
  //   combinedBalance,
  //   phase1Config?.phase1SellPrice,
  // ]);

  return (
    <div className={styles.About}>
      <div className={styles.banner} data-aos="fade-up">
        <ProgressiveVideo
          imgSrc="/static/images/About/bannerBg.jpg"
          videoSrc="/static/images/About/bannerBg.mp4"
        />
        <MainContainer className={styles.bannerTextWrap}>
          <TitleCombo>
            <h2>{t('Zebec Operation Horizon', 'Zebec Operation Horizon')}</h2>

            <p>
              <Trans i18nKey="intro">
                Welcome to Operation Horizon, Zebec’s campaign to launch a high-throughput,
                payment-focused Layer 3 Blockchain. Zebec’s amazing community will be at the core of
                this operation and will help bootstrap the network through the Zepoch Nodes.
              </Trans>
            </p>
          </TitleCombo>
          <div className={styles.bannerButtonWrap}>
            <NavLinkWithQuery to="/participate">
              <CustomButton>{t('Get Zepoch Now', 'Get Zepoch Now')}</CustomButton>
            </NavLinkWithQuery>
            <a href="https://horizon.zebec.io/docs/zebecWhitepaper.pdf">
              <CustomButton secondary className={styles.whitepaperButton}>
                {t('Zebec Chain Whitepaper', 'Zebec Chain Whitepaper')}
              </CustomButton>
            </a>
          </div>
        </MainContainer>
      </div>
      <div
        className={styles.mainContent}
        // style={{ backgroundImage: 'url(/static/images/About/bg.jpg)' }}
      >
        <MainContainer className={styles.mainContainer}>
          <div className={styles.getNodeWrap} data-aos="fade-up">
            <TitleCombo className={styles.getNodeCombo} small>
              <h2>
                {t('Participating In Operation Horizon', 'Participating In Operation Horizon')}
              </h2>
              <p>
                <Trans i18nKey="ZebecIntro">
                  Participating in Operation Horizon will help us expand Zebec Protocol and
                  revolutionize payments in Web2 and Web3. By participating in Operation Horizon,
                  you will passively earn token rewards and have access to exclusive privileges.
                </Trans>
              </p>
            </TitleCombo>
            <div className={styles.buttonWrap}>
              <NavLinkWithQuery to="/participate">
                <CustomButton className={styles.getNftButton}>
                  {t('Get Zepoch Now', 'Get Zepoch Now')}
                </CustomButton>
              </NavLinkWithQuery>
              <NavLinkWithQuery to="/learnMore">
                <CustomButton className={styles.getNftButton} secondary>
                  {t('Learn More', 'Learn more')}
                </CustomButton>
              </NavLinkWithQuery>
            </div>
            <div className={styles.statsWrap}>
              <Row gutter={[16, 16]} justify="center">
                {stats.map((item) => (
                  <Col
                    span={24}
                    // eslint-disable-next-line
                    md={12}
                    lg={12}
                    xl={6}
                    key={item.id}>
                    <div className={styles.statsItem}>
                      <div className={styles.statsIconWrap}>
                        <img src={`/static/images/About/statsIcon${item.img}.svg`} alt="icon" />
                      </div>
                      <div className={styles.statsTextWrap}>
                        <h2 className={commonStyles.gradientTitle}>{t(item.text)}</h2>
                        <p>{item.value}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </MainContainer>
        <div
          className={styles.nftIntroWrap}
          style={{ backgroundImage: 'url(/static/images/About/nftIntroWrapBg.svg)' }}>
          <MainContainer className={styles.nftIntro}>
            <TitleCombo small>
              <h2>{t('Key Components', 'Key Components')}</h2>
            </TitleCombo>
            <div className={styles.nftIntroItem}>
              <video
                autoPlay
                muted
                loop
                playsInline
                poster="/static/images/NFT/zepoch.png"
                data-aos="fade-right">
                <source src="/static/images/NFT/zepoch.mp4" type="video/mp4" />
              </video>
              <div className={styles.nftIntroAction} data-aos="fade-left">
                <h2>{t('ZBC Zepoch Node NFT', 'Zepoch Node NFT')}</h2>
                <p>
                  <Trans i18nKey="HoldersBenefit">
                    A Zepoch Node NFT serves as your proof of identity, granting you the ability to
                    start earning $ZBC rewards immediately after purchase and enabling you to
                    operate a validator node when Zebec Chain goes live.
                  </Trans>
                </p>
                <NavLinkWithQuery to="/participate">
                  <CustomButton>{t('Become a Validator', 'Become a Validator')}</CustomButton>
                </NavLinkWithQuery>
              </div>
            </div>
            <div className={classNames(styles.nftIntroItem, styles.second)}>
              <div
                className={classNames(styles.nftIntroAction, styles.second)}
                data-aos="fade-right">
                <h2>{t('Themis Gavel NFTs', 'Themis Gavel NFTs')}</h2>
                <p>
                  <Trans i18nKey="HonorHolders">
                    Themis Gavel is a soul-bound NFT that uses the EIP-5114 Standard and is used for
                    governance purposes as well as to interact with the Zebec DAO.
                  </Trans>
                </p>
                <NavLinkWithQuery to="/participate">
                  <CustomButton>{t('Hodl a Gavel', 'Hodl a Gavel')}</CustomButton>
                </NavLinkWithQuery>
              </div>

              <video
                autoPlay
                muted
                loop
                playsInline
                poster="/static/images/NFT/themis.png"
                data-aos="fade-left">
                <source src="/static/images/NFT/themis.mp4" type="video/mp4" />
              </video>
            </div>
          </MainContainer>
        </div>

        <div
          className={styles.pointsSystemWrap}
          // style={{ backgroundImage: 'url(/static/images/About/pointsSystemBg.png)' }}
        >
          <MainContainer className={styles.pointsSystemContainer}>
            <TitleCombo small>
              <h2 data-aos="fade-up">
                {t('Build Zebec Chain, Earn Points', 'Build the Zebec Chain, Earn Points')}
              </h2>
              <p data-aos="fade-up">
                <Trans i18nKey="ZebecPointIntro">
                  Zebec Points incentivize community contribution to develop Zebec’s ecosystem.
                  Zebec Points will reward holders for referring users and participating in Zebec’s
                  ecosystem. The more Zebec Points a Zebec community member has, the more tokens you
                  will receive in Operation Horizon.
                </Trans>
              </p>
            </TitleCombo>
            <NavLinkWithQuery to="/points">
              <CustomButton>{t('Join Now', 'Join Now')}</CustomButton>
            </NavLinkWithQuery>
          </MainContainer>
        </div>
        <MainContainer className={styles.saleWrap}>
          <img
            className={styles.saleWatermark}
            src="/static/images/About/saleWatermark.svg"
            alt="zepoch"
          />
          <div className={styles.saleTextWrap}>
            <div className={styles.logoWrap}>
              <img src="/static/images/About/logo.svg" alt="logo" />
              <h2>Zebec</h2>
            </div>
            <div className={styles.vbar} />
            <h2>ZEPOCH NODE ON SALE</h2>
          </div>
          <NavLinkWithQuery to="/participate">
            <CustomButton className={styles.buyButton} rounded>
              {t('Get Zepoch Now', 'Get Zepoch Now')}
            </CustomButton>
          </NavLinkWithQuery>
        </MainContainer>
      </div>
    </div>
  );
};

export default About;
