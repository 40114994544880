import { constants, ethers } from 'ethers';
import { useEffect, useState, useCallback, useReducer } from 'react';
import {
  useContract,
  useERC20Contract,
  useZepochNodeContract,
  useZPointContract,
} from './useContract';
import { useIsMounted } from './useIsMounted';

import { useWallet } from '../store/wallet-context';
import { useUpdate } from './useUpdate';
import { CONTRACT_NAME, NULL_ADDR } from '../constants/addresses';
import { useNameservice } from '../store/nameservice-context';
import { FETCH_STATE, readContractReducer } from '../utils/utils';

// get balance of erc20/native token
export const useTokenBalance = (tokenAddress, unit = 18, accountAddress = null, convert = true) => {
  const formatUnits = useCallback(
    (number) => Number(ethers.utils.formatUnits(number, unit)),
    [unit],
  );

  const initValue = convert ? 0 : constants.Zero;
  const tokenContract = useERC20Contract(tokenAddress);
  const { state, read } = useContract(
    tokenContract,
    'balanceOf',
    convert ? formatUnits : null,
    initValue,
  );
  const { account, ethersProvider } = useWallet();
  const { updateByTimer } = useUpdate();
  const isMounted = useIsMounted();
  const isNative = parseInt(tokenAddress, 16) === 0;
  const [nativeBalance, setNativeBalance] = useState(initValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !(ethersProvider && tokenContract && (ethers.utils.isAddress(accountAddress) || account))
        ) {
          return;
        }
        if (isNative) {
          const rawBalance = await ethersProvider.getBalance(accountAddress || account);
          const balance = convert ? Number(ethers.utils.formatEther(rawBalance)) : rawBalance;
          if (isMounted) {
            setNativeBalance(balance);
          }
        } else {
          read([accountAddress || account]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [
    tokenContract,
    account,
    accountAddress,
    ethersProvider,
    isMounted,
    updateByTimer,
    isNative,
    read,
    convert,
  ]);

  return {
    ...state,
    data: isNative ? nativeBalance : state.data,
  };
};

export const useTokenTotalSupply = (
  tokenAddress,
  unit = 18,
  convert = true,
) => {
  const formatUnits = useCallback(
    (number) => Number(ethers.utils.formatUnits(number, unit)),
    [unit],
  );

  const initValue = convert ? 0 : constants.Zero;
  const tokenContract = useERC20Contract(tokenAddress);
  const { state, read } = useContract(
    tokenContract,
    'totalSupply',
    convert ? formatUnits : null,
    initValue,
  );
  const { ethersProvider } = useWallet();
  const { updateByTimer } = useUpdate();

  useEffect(() => {
    const fetchData = () => {
      if (
        !(
          ethersProvider &&
          tokenContract
        )
      ) {
        return;
      }
      read();
    };
    fetchData();
  }, [
    tokenContract,
    ethersProvider,
    updateByTimer,
    read,
  ]);
  return state;
};

export const useTokenAllowance = (
  tokenAddress,
  targetContractAddress,
  unit = 18,
  accountAddress = null,
  convert = true,
) => {
  const formatUnits = useCallback(
    (number) => Number(ethers.utils.formatUnits(number, unit)),
    [unit],
  );

  const initValue = convert ? 0 : constants.Zero;
  const tokenContract = useERC20Contract(tokenAddress);
  const { state, read } = useContract(
    tokenContract,
    'allowance',
    convert ? formatUnits : null,
    initValue,
  );
  const { account, ethersProvider } = useWallet();
  const { updateByTimer } = useUpdate();

  useEffect(() => {
    const fetchData = () => {
      if (
        !(
          targetContractAddress &&
          ethersProvider &&
          tokenContract &&
          (ethers.utils.isAddress(accountAddress) || account)
        )
      ) {
        return;
      }
      read([accountAddress || account, targetContractAddress]);
    };
    fetchData();
  }, [
    tokenContract,
    account,
    accountAddress,
    ethersProvider,
    updateByTimer,
    targetContractAddress,
    read,
  ]);
  return state;
};

export const useTokenApprove = (tokenAddress) => {
  const tokenContract = useERC20Contract(tokenAddress);
  const { state, write } = useContract(tokenContract, 'approve');
  const { account } = useWallet();

  const approve = async (
    targetAddress,
    amount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
  ) => {
    if (!(tokenContract && account)) {
      return;
    }
    await write([targetAddress, amount]);
  };

  return { state, approve };
};

export const useAllTokenBalance = () => {
  const addresses = useNameservice();
  const nativeBalance = useTokenBalance(NULL_ADDR);
  const zbcBalance = useTokenBalance(addresses[CONTRACT_NAME.ZBC], 9);
  const zbcLegacyBalance = useTokenBalance(addresses[CONTRACT_NAME.ZBCLegacy], 9);
  const usdtBalance = useTokenBalance(addresses[CONTRACT_NAME.USDT]);
  const usdcBalance = useTokenBalance(addresses[CONTRACT_NAME.USDC]);
  const busdBalance = useTokenBalance(addresses[CONTRACT_NAME.BUSD]);
  const zpointBalance = useTokenBalance(addresses[CONTRACT_NAME.ZPoint]);
  return {
    nativeBalance,
    [CONTRACT_NAME.ZBC]: zbcBalance,
    [CONTRACT_NAME.ZBCLegacy]: zbcLegacyBalance,
    [CONTRACT_NAME.USDT]: usdtBalance,
    [CONTRACT_NAME.BUSD]: busdBalance,
    [CONTRACT_NAME.USDC]: usdcBalance,
    [CONTRACT_NAME.ZPoint]: zpointBalance,
  };
};

export const useAllTokenAllowance = (targetContractAddress) => {
  const addresses = useNameservice();
  const zbcAllowance = useTokenAllowance(addresses[CONTRACT_NAME.ZBC], targetContractAddress, 9);
  const zbcLegacyAllowance = useTokenAllowance(
    addresses[CONTRACT_NAME.ZBCLegacy],
    targetContractAddress,
    9,
  );
  const usdtAllowance = useTokenAllowance(addresses[CONTRACT_NAME.USDT], targetContractAddress);
  const busdAllowance = useTokenAllowance(addresses[CONTRACT_NAME.BUSD], targetContractAddress);
  const usdcAllowance = useTokenAllowance(addresses[CONTRACT_NAME.USDC], targetContractAddress);
  return {
    [CONTRACT_NAME.ZBC]: zbcAllowance,
    [CONTRACT_NAME.ZBCLegacy]: zbcLegacyAllowance,
    [CONTRACT_NAME.USDT]: usdtAllowance,
    [CONTRACT_NAME.BUSD]: busdAllowance,
    [CONTRACT_NAME.USDC]: usdcAllowance,
  };
};

export const useZepochNodeTotalSupply = () => {
  const decoder = useCallback((config) => config.toNumber(), []);
  const initValue = 0;
  const zepochNodeContract = useZepochNodeContract();
  const { state, read } = useContract(zepochNodeContract, 'totalSupply', decoder, initValue);
  const { updateByTimer } = useUpdate();

  useEffect(() => {
    const fetchData = () => {
      if (!zepochNodeContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, zepochNodeContract]);
  return state;
};

// export const useZepochNodeOwners = () => {
//   const zepochNodeContract = useZepochNodeContract();
//   const [owners, setOwners] = useState([]);

//   useEffect(() => {
//     const getOwners = async () => {
//       if (zepochNodeContract) {
//         const totalSupply = (await zepochNodeContract.totalSupply()).toNumber();
//         const tempOwners = await Promise.all(
//           [...Array(totalSupply).keys()].map((item) => zepochNodeContract.ownerOf(item + 1)),
//         );
//         console.log([...new Set(tempOwners)]);
//       }
//     };
//     getOwners();
//   }, [zepochNodeContract]);
// };

export const useZPointTotalSupply = () => {
  const formatEther = useCallback((number) => Number(ethers.utils.formatEther(number)), []);

  const initValue = 0;
  const zPointContract = useZPointContract();
  const { state, read } = useContract(zPointContract, 'totalSupply', formatEther, initValue);
  const { updateByTimer } = useUpdate();

  useEffect(() => {
    const fetchData = () => {
      if (!zPointContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, zPointContract]);
  return state;
};

export const useVaultBalance = () => {
  const [state, dispatch] = useReducer(readContractReducer, {
    isLoading: false,
    isError: false,
    data: { USDTBalance: '', BUSDBalance: '', combinedBalance: '' },
  });

  const { [CONTRACT_NAME.USDT]: USDTAddress } = useNameservice();
  const { [CONTRACT_NAME.BUSD]: BUSDAddress } = useNameservice();
  const { [CONTRACT_NAME.Vault]: vaultAddress } = useNameservice();

  const USDTContract = useERC20Contract(USDTAddress);
  const BUSDContract = useERC20Contract(BUSDAddress);

  const isMounted = useIsMounted();
  const { updateByTimer } = useUpdate();

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!(USDTContract && BUSDContract && vaultAddress)) {
          return;
        }
        dispatch({ type: FETCH_STATE.INIT });

        const [USDTBalance, BUSDBalance] = await Promise.all([
          USDTContract.balanceOf(vaultAddress),
          BUSDContract.balanceOf(vaultAddress),
        ]);
        if (isMounted.current) {
          dispatch({
            type: FETCH_STATE.SUCCESS,
            payload: {
              USDTBalance: ethers.utils.formatEther(USDTBalance).toString(),
              BUSDBalance: ethers.utils.formatEther(BUSDBalance).toString(),
              combinedBalance: ethers.utils.formatEther(USDTBalance.add(BUSDBalance)).toString(),
            },
          });
        }
      } catch (error) {
        console.log(error);
        if (isMounted.current) {
          dispatch({ type: FETCH_STATE.FAILURE });
        }
      }
    };
    fetch();
  }, [USDTContract, BUSDContract, isMounted, updateByTimer, vaultAddress]);

  return state;
};

// export const use721Allowance = (tokenAddress, targetContractAddress) => {
//   const initValue = false;
//   const tokenContract = useERC721Contract(tokenAddress);
//   const { state, read } = useContract(tokenContract, 'isApprovedForAll', null, initValue);
//   const { account, ethersProvider } = useWallet();
//   const { updateByTimer } = useUpdate();

//   useEffect(() => {
//     const fetchData = () => {
//       if (!(targetContractAddress && ethersProvider && tokenContract && account)) {
//         return;
//       }
//       read([account, targetContractAddress]);
//     };
//     fetchData();
//   }, [tokenContract, account, ethersProvider, updateByTimer, targetContractAddress, read]);
//   return state;
// };

// export const use721Approve = (tokenAddress) => {
//   const tokenContract = useERC721Contract(tokenAddress);
//   const { state, write } = useContract(tokenContract, 'setApprovalForAll');

//   const approve = (targetAddress) => {
//     console.log('handle approve');

//     if (!tokenContract) {
//       return;
//     }
//     write([targetAddress, true]);
//   };

//   return { state, approve };
// };
