import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ProgressiveVideo.module.scss';

const ProgressiveVideo = (props) => {
  const { className, videoSrc, imgSrc } = props;
  const [videoPlaying, setVideoPlaying] = useState(false);
  return (
    <div className={classNames(styles.ProgressiveVideo, className)}>
      <img src={imgSrc} alt="placeholder" style={{ opacity: videoPlaying ? 0 : 1 }} />

      <video
        autoPlay
        muted
        loop
        playsInline
        onPlaying={() => {
          setVideoPlaying(true);
        }}
        style={{ opacity: videoPlaying ? 1 : 0 }}>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
};

ProgressiveVideo.propTypes = {
  className: PropTypes.string,
  videoSrc: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

ProgressiveVideo.defaultProps = {
  className: '',
};

export default ProgressiveVideo;
