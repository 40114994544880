import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const apiKey = 'ncyEbCT4n4iUwD-FJEIcfQ';
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['default'],
    defaultNS: 'default',
    allowObjectInHTMLChildren: true, // temporary solution to interpolation of Trans component problem
    detection: { order: ['localStorage', 'htmlTag'] },

    supportedLngs: [
      'en',
      'ms', // Malay
      'ko',
      'ru', // Russian
      'es', // Spanish
      'hi', // Hindi
      'vi',
      'ja',
      'zh',
      'ar', // Arabic
      'id', // Indonesian
    ],

    backend: {
      loadPath,
    },
    react: {
      // nodes allowed to be kept
      transKeepBasicHtmlNodesFor: [
        'br',
        'strong',
        'i',
        'p',
        'span',
        'ul',
        'li',
        'NavLinkWithQuery',
      ],
    },
  });
