/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import styles from './Tab.module.scss';

const Tab = ({ onChange, children, className }) => {
  const tabValues = children.map((child) => child.props.tag);
  const [activeTab, setActiveTab] = React.useState(tabValues[0]);

  const handleTabChange = (tabValue) => {
    if (tabValue === activeTab) {
      console.log('skip tab change');
    }
    onChange(tabValue);
    setActiveTab(tabValue);
  };

  return (
    <div className={classNames(styles.Tab, className)}>
      {children.map((child) => {
        const { name } = child.type;
        const tabValue = child.props.children;
        // const isLabel = name === 'TabLabel';
        const { tag, disabled } = child.props
        const isActive = activeTab === tag;
        // if (!isLabel) return null;
        return (
          <div
            key={`${name}-${tabValue}`}
            className={classNames({
              [styles.active]: isActive,
            })}
            onClick={() => {
              if(!disabled){
                handleTabChange(tag)
              }}
            }>
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default Tab;
