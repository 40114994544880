import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Contract, ethers } from 'ethers';

import { useIsMounted } from '../hooks/useIsMounted';
import { useWallet } from './wallet-context';
import { ADDRESSES } from '../constants/addresses';
import ZHNameServiceInterface from '../abis/ZHNameServiceInterface.json';

export const useNameserviceContract = () => {
  const { ethersProvider } = useWallet();
  return useMemo(() => {
    if (!ethersProvider) {
      return null;
    }
    return new Contract(ADDRESSES.NAMESERVICE, ZHNameServiceInterface.abi, ethersProvider);
  }, [ethersProvider]);
};

const nameserviceContext = createContext();

export const useNameservice = () => useContext(nameserviceContext);

const decodeEntries = (records) =>
  records.reduce(
    (accum, item) => ({ ...accum, [ethers.utils.parseBytes32String(item.name)]: item.addr }),
    {},
  );

const useProvideNameservice = () => {
  const [addresses, setAddresses] = useState({});
  const nameserviceContract = useNameserviceContract();
  const isMounted = useIsMounted();

  useEffect(() => {
    const fetchAddresses = async () => {
      if (!nameserviceContract) {
        return;
      }
      try {
        const singleEntries = await nameserviceContract.listSingleEntries();
        const decodedSingleEntries = decodeEntries(singleEntries);
        const multiEntries = await nameserviceContract.listMultipleEntries();
        const decodedMultiEntries = decodeEntries(multiEntries);

        if (isMounted.current) {
          setAddresses({ ...decodedSingleEntries, ...decodedMultiEntries });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAddresses();
  }, [isMounted, nameserviceContract]);

  return { ...addresses };
};

export const ProvideNameservice = ({ children }) => {
  const addresses = useProvideNameservice();
  return <nameserviceContext.Provider value={addresses}>{children}</nameserviceContext.Provider>;
};

ProvideNameservice.propTypes = {
  children: PropTypes.node.isRequired,
};
