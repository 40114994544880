import { useLayoutEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import TitleCombo from '../../components/UI/TitleCombo/TitleCombo';
import styles from './LearnMore.module.scss';

const corePhilosophy = [
  {
    icon: '/static/images/About/statsIconZebec.svg',
    title: 'Zebec - The New Standard of Payments',
    content: 'corePhilosophy-1-content',
    // To create a borderless and frictionless payment standard, Zebec will be introducing a payment-focused layer 1 chain in 2023. The Zepoch Node holders will be able to participate in the early development of this new chain and receive limited NFTs.
  },
  {
    icon: '/static/images/About/statsIconPoints.svg',
    title: 'Grow together with Zebec',
    content: 'corePhilosophy-2-content',
    // The Node Holders are essentially a stakeholder of the Zebec Ecosystem, playing a huge part in the future development of building the new payment standards. Apart from governance rights, holders will also share major percentages of future revenue flows from the Zebec Ecosystem and a series of rewards.
  },
];

const infrastructure = [
  {
    icon: '/static/images/LearnMore/zebecChain.png',
    title: 'Zebec Chain',
    content: 'infrastructure-1-content',
    // Zebec Chain provides payment infrastructure to the decentralized network. This includes low cost and fast payment (efficiency), privacy for payment (Zero Knowledge Proof), and compliance with authority.
  },
  {
    icon: '/static/images/LearnMore/paymentIndustry.png',
    title: 'Payment Industry',
    content: 'infrastructure-2-content',
    // This includes web 2.0 payroll companies, web 3.0 DeFi protocols, private payroll, 401K, and Zebec debit cards.
  },
  {
    icon: '/static/images/LearnMore/distributionAlgo.png',
    title: 'The Governance-Based Random Distribution Algo',
    content: 'infrastructure-3-content',
    // This part of the Ecosystem is responsible for distributing NFT rewards to the node network. The exact distributing mechanism will be released soon.
  },
  {
    icon: '/static/images/LearnMore/easyToAccessEndpoints.png',
    title: 'Public Database (DB) with Easy-to-Access Endpoints',
    content: 'infrastructure-4-content',
    // The Zebec Node Ecosystem will depend on the work of the developer community who are willing to put their time into growing the decentralized ecosystem. To do that, there will be the creation of a publicly accessible database with easy-to-access endpoints.
  },
];

const LearnMore = () => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.LearnMore}>
      <div className={styles.Card}>
        <TitleCombo>
          <h2>{t('ZBC Zepoch Node Ecosystem', 'ZBC Zepoch Node Ecosystem')}</h2>
        </TitleCombo>
        <div className={styles.CorePhilosoph}>
          <TitleCombo className={styles.EcosystemTitleSec}>
            <h2>{t('Core Philosophy', 'Core Philosophy')}</h2>
          </TitleCombo>
          <div className={styles.CorePhilosophWarp}>
            {corePhilosophy.map((item) => (
              <div className={styles.CorePhilosophItem} key={item.title}>
                <div>
                  <img src={item.icon} alt="Ecosystem Map" />
                </div>
                <div className={styles.CorePhilosophItemContentWarp}>
                  <div className={styles.CorePhilosophItemTitle}>{t(item.title)}</div>
                  <div className={styles.CorePhilosophItemContent}>{t(item.content)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.EcosystemMap}>
        <TitleCombo className={styles.EcosystemTitleSec}>
          <h2>{t('Ecosystem Map', 'Ecosystem Map')}</h2>
          <img src="/static/images/LearnMore/circuitBoardCompressed.png" alt="Ecosystem Map" />
        </TitleCombo>
      </div>
      <div className={styles.Infrastructure}>
        <TitleCombo className={styles.EcosystemTitleSec}>
          <h2>
            {' '}
            {t('Key Component of Zebec Infrastructure:', 'Key Component of Zebec Infrastructure:')}
          </h2>
        </TitleCombo>
        <div className={styles.InfrastructureWarp}>
          <Row gutter={[32, 16]}>
            {infrastructure.map((item) => (
              <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} key={item.title}>
                <div className={styles.InfrastructureItem}>
                  <img src={item.icon} alt={item.title} />
                  <TitleCombo className={styles.InfrastructureItemTitle}>
                    <h2>{t(item.title)}</h2>
                  </TitleCombo>
                  <div className={styles.InfrastructureItemContent}>{t(item.content)}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
