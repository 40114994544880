import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './MainContainer.module.scss';

const MainContainer = (props) => {
  const { className, children, style } = props;
  return (
    <div className={classnames(styles.MainContainer, className)} style={style}>
      {children}
    </div>
  );
};

MainContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.string),
};

MainContainer.defaultProps = {
  className: '',
  children: null,
  style: {},
};

export default MainContainer;
