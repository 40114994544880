import { message } from 'antd';
import { formatEther } from 'ethers/lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useClaimUsdtAndBusdViaVault } from '../../../hooks/useManager';
import { useAllTokenBalance } from '../../../hooks/useToken';
import { useTreasuryClaimAmount } from '../../../hooks/useTreasuryClaimAmount';
import { useTreasuryClaimFomoAmount } from '../../../hooks/useTreasuryClaimFomoAmount';
import { useWallet } from '../../../store/wallet-context';
import { formatNumber } from '../../../utils/utils';

import CustomButton from '../../UI/CustomButton/CustomButton';

import styles from './RewardsPoolAction.module.scss';

const RewardsPoolAction = () => {
  const claimData = useTreasuryClaimAmount();
  const { state: claimUsdtAndBusdViaVaultState, claimUsdtAndBusdViaVault } =
    useClaimUsdtAndBusdViaVault();
  const { account } = useWallet();
  const { t } = useTranslation();
  const allTokenBalance = useAllTokenBalance();
  const { data: nativeBalance } = allTokenBalance.nativeBalance;

  const claimFomoData = useTreasuryClaimFomoAmount();
  const handleClaim = async () => {
    if (claimUsdtAndBusdViaVaultState.isLoading) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance < 0.01) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    if (
      !claimData.usdtAmount ||
      !claimData.busdAmount ||
      !claimData.usdtAmount.add(claimData.busdAmount).gt(0)
    ) {
      message.info(t('No bonus to claim', 'No bonus to claim'));
      return;
    }

    try {
      await claimUsdtAndBusdViaVault(claimData);
    } catch (error) {
      message.info(t('Claim error', 'Claim error'));
    }
  };

  const handleClaimFomo = async () => {
    if (claimUsdtAndBusdViaVaultState.isLoading) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance < 0.01) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    if (
      !claimFomoData.usdtAmount ||
      !claimFomoData.busdAmount ||
      !claimFomoData.usdtAmount.add(claimFomoData.busdAmount).gt(0)
    ) {
      message.info(t('No bonus to claim', 'No bonus to claim'));
      return;
    }

    try {
      await claimUsdtAndBusdViaVault(claimFomoData);
    } catch (error) {
      message.info(t('Claim error', 'Claim error'));
    }
  };
  return (
    <div className={styles.RewardsPoolAction}>
      {/* <img src="/static/images/RewardsPool/ticket.svg" alt="icon" />
      <h2 className={styles.title}>{t('Your Bonus', 'Your Bonus')}</h2> */}
      <div className={styles.actionWrap}>
        <h2>{t('Bonus', 'Bonus')}</h2>
        <p>
          {formatNumber(
            formatEther(claimData.usdtAmount?.add(claimData.busdAmount) ?? '0'),
            '$0,0.[00]',
          )}
        </p>
        <CustomButton
          secondary
          className={styles.claimButton}
          loading={claimUsdtAndBusdViaVaultState.isLoading}
          // disabled={
          //   !(
          //     claimData.usdtAmount?.add(claimData.busdAmount).gt(0) ||
          //     claimFomoData.usdtAmount?.add(claimFomoData.busdAmount).gt(0)
          //   )
          // }
          onClick={
            claimData.usdtAmount?.add(claimData.busdAmount).gt(0) ? handleClaim : handleClaimFomo
          }>
          {t('Claim', 'Claim')}
        </CustomButton>
      </div>
    </div>
  );
};

export default RewardsPoolAction;
