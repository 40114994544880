import { constants, utils } from 'ethers';
import { useEffect } from 'react';
import { CONTRACT_NAME } from '../constants/addresses';
import { useNameservice } from '../store/nameservice-context';
import { useWallet } from '../store/wallet-context';
import { DAY } from '../utils/utils';
import { useContract, useLinearUnlockContract } from './useContract';
import { useUpdate } from './useUpdate';

const BILL_CYCLE = 3 * DAY;
const LOCK_DURATION = 180 * DAY;

export const useLinearUnlock = () => {
  const linearUnlockContract = useLinearUnlockContract();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();
  const { [CONTRACT_NAME.ZBC]: zbcAddress } = useNameservice();

  const { state: lockedAmountState, read: readLockedAmount } = useContract(
    linearUnlockContract,
    'lockedAmount',
    null,
    constants.Zero,
  );

  const { state: unlockedAmountState, read: readUnlockedAmount } = useContract(
    linearUnlockContract,
    'unlockedAmount',
    null,
    constants.Zero,
  );

  const { state: personRecordState, read: readPersonRecord } = useContract(
    linearUnlockContract,
    'person',
    null,
    {},
  );

  useEffect(() => {
    const fetchData = () => {
      if (!(linearUnlockContract && utils.isAddress(account) && utils.isAddress(zbcAddress))) {
        return;
      }
      readLockedAmount([BILL_CYCLE, LOCK_DURATION, zbcAddress, account, 0]);
      readUnlockedAmount([BILL_CYCLE, LOCK_DURATION, zbcAddress, account, 0]);
      readPersonRecord([BILL_CYCLE, LOCK_DURATION, zbcAddress, account]);
    };
    fetchData();
  }, [
    linearUnlockContract,
    account,
    updateByTimer,
    readLockedAmount,
    readUnlockedAmount,
    readPersonRecord,
    zbcAddress,
  ]);
  
  return {
    lockedAmountState,
    unlockedAmountState,
    personRecordState,
  };
};

export const useUnlock = () => {
  const linearUnlockContract = useLinearUnlockContract();

  const { account } = useWallet();

  const { state, write } = useContract(linearUnlockContract, 'unlock');

  const { [CONTRACT_NAME.ZBC]: zbcAddress } = useNameservice();

  const unlock = async () => {
    if (!(linearUnlockContract && account)) {
      return;
    }
    await write([BILL_CYCLE, LOCK_DURATION, zbcAddress]);
  };

  return { state, unlock };
};
