import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Contract, ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useWallet } from './wallet-context';
import { CONTRACT_NAME } from '../constants/addresses';
import { useNameservice } from './nameservice-context';
import AirdropPoseInterface from '../abis/AirdropPoseInterface.json';
import AirdropPosePhase3Interface from '../abis/AirdropPosePhase3Interface.json';
import { useContract } from '../hooks/useContract';
import { useTokenBalance } from '../hooks/useToken';
import { DAY, checkTime } from '../utils/utils';
import { useUpdate } from '../hooks/useUpdate';
import { usePOSEPrice } from '../hooks/usePOSEPrice';

export const AIRDROP_POSE_NAME = {
  AIRDROP1: { key: 'AIRDROP1', name25: 'AirdropPhaseOne25', name75: 'AirdropPhaseOne75' },
  AIRDROP2: { key: 'AIRDROP2', name25: 'AirdropPhaseTwo25', name75: 'AirdropPhaseTwo75' },
};

export const useAirdropPoseContract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.AirdropPose]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, AirdropPoseInterface.abi, ethersProvider.getSigner())
      : new Contract(addr, AirdropPoseInterface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

export const useAirdropPosePhase3Contract = () => {
  const { ethersProvider, account } = useWallet();
  const { [CONTRACT_NAME.AirdropPosePhase3]: addr } = useNameservice();
  return useMemo(() => {
    if (!(ethersProvider && addr)) {
      return null;
    }
    return account
      ? new Contract(addr, AirdropPosePhase3Interface.abi, ethersProvider.getSigner())
      : new Contract(addr, AirdropPosePhase3Interface.abi, ethersProvider);
  }, [account, ethersProvider, addr]);
};

const airdropPoseContext = createContext();

export const useAirdropPose = () => useContext(airdropPoseContext);

const useProvideAirdropPose = () => {
  const [targetName, setTargetName] = useState();
  const airdropPoseContract = useAirdropPoseContract();
  const airdropPosePhase3Contract = useAirdropPosePhase3Contract();
  const { account } = useWallet();
  const { t } = useTranslation();
  const { data: nativeBalance } = useTokenBalance(ethers.constants.AddressZero, 18, null, false);
  const [airdropPose25Claimable, setAirdropPose25Claimable] = useState();
  const [airdropPose75Claimable, setAirdropPose75Claimable] = useState();
  const [configs, setConfigs] = useState();
  const [records, setRecords] = useState();
  const { updateByTimer } = useUpdate();

  // get both configs
  useEffect(() => {
    const fetch = async () => {
      try {
        if (!(targetName && airdropPoseContract)) {
          return;
        }
        const airdropPose25Config = await airdropPoseContract.getAirdropPose25Config(
          ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name25),
        );
        const airdropPose75Config = await airdropPoseContract.getAirdropPose75Config(
          ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name75),
        );
        setConfigs({ airdropPose25Config, airdropPose75Config });
      } catch (error) {
        console.error('getAirdropPoseConfig Failed', error);
      }
    };
    fetch();
  }, [airdropPoseContract, targetName]);

  // get both records
  useEffect(() => {
    const fetch = async () => {
      try {
        if (!(targetName && airdropPoseContract && account)) {
          return;
        }
        const airdropPose25Record = await airdropPoseContract.getAirdropPose25Record(
          ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name25),
          account,
        );

        const airdropPose75Record = await airdropPoseContract.getAirdropPose75Record(
          ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name75),
          account,
        );
        setRecords({
          airdropPose25Record: {
            ...airdropPose25Record,
            isReadyToClaim: checkTime(
              airdropPose25Record.lastUnlockTimestamp.toNumber() * 1000 + DAY * 1000 * 15,
            ),
          },
          airdropPose75Record: {
            ...airdropPose75Record,
            isReadyToClaim: checkTime(
              airdropPose75Record.lastUnlockTimestamp.toNumber() * 1000 + DAY * 1000 * 15,
            ),
          },
        });
      } catch (error) {
        console.error('getAirdropPoseRecord Failed', error);
      }
    };
    fetch();
  }, [account, airdropPoseContract, targetName, updateByTimer]);

  // get both claimable
  useEffect(() => {
    const fetch = async () => {
      try {
        if (!(targetName && airdropPoseContract && account && records)) {
          return;
        }
        if (
          records.airdropPose25Record.who !== ethers.constants.AddressZero &&
          records.airdropPose25Record.isReadyToClaim
        ) {
          const claimable25 = await airdropPoseContract.callStatic.claimAirdropPose25Record(
            ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name25),
            { from: account },
          );
          setAirdropPose25Claimable(claimable25);
        }
        if (
          records.airdropPose75Record.who !== ethers.constants.AddressZero &&
          records.airdropPose75Record.isReadyToClaim
        ) {
          const claimable75 = await airdropPoseContract.callStatic.claimAirdropPose75Record(
            ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name75),
            { from: account },
          );
          setAirdropPose75Claimable(claimable75);
        }
      } catch (error) {
        console.error('airdropPoseClaimable Failed', error);
      }
    };
    fetch();
  }, [account, airdropPoseContract, targetName, updateByTimer, records]);

  // set target name
  const setTargetNameHandler = useCallback((name) => {
    setAirdropPose25Claimable();
    setAirdropPose75Claimable();
    setRecords();
    setConfigs();
    if (Object.keys(AIRDROP_POSE_NAME).includes(name)) {
      setTargetName(name);
      return;
    }
    setTargetName();
  }, []);

  const { state: claimAirdropPose25RecordState, write: claimAirdropPose25Write } = useContract(
    airdropPoseContract,
    'claimAirdropPose25Record',
  );
  const claimAirdropPose25Record = useCallback(async () => {
    if (
      !(
        targetName &&
        airdropPoseContract &&
        claimAirdropPose25Write &&
        !claimAirdropPose25RecordState.isLoading &&
        configs?.airdropPose25Config &&
        records?.airdropPose25Record
      )
    ) {
      return;
    }
    if (records.airdropPose25Record.who === ethers.constants.AddressZero) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet'));
      return;
    }
    if (!checkTime(configs.airdropPose25Config.startTimestamp.toNumber() * 1000)) {
      message.info(t('The event has not started yet', 'The event has not started yet'));
      return;
    }
    if (records.airdropPose25Record.unlockedAll) {
      message.info(t('You have unlocked all the rewards', 'You have unlocked all the rewards'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(t("You don't have enough BNB for gas to complete the transaction"));
      return;
    }
    try {
      await claimAirdropPose25Write([
        ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name25),
      ]);
    } catch (e) {
      console.error('claimAirdropPose25 failed', e);
    }
  }, [
    account,
    airdropPoseContract,
    claimAirdropPose25Write,
    nativeBalance,
    t,
    targetName,
    claimAirdropPose25RecordState.isLoading,
    configs?.airdropPose25Config,
    records?.airdropPose25Record,
  ]);

  const { state: claimAirdropPose75RecordState, write: claimAirdropPose75Write } = useContract(
    airdropPoseContract,
    'claimAirdropPose75Record',
  );
  const claimAirdropPose75Record = useCallback(async () => {
    if (
      !(
        targetName &&
        airdropPoseContract &&
        claimAirdropPose75Write &&
        !claimAirdropPose75RecordState.isLoading &&
        configs?.airdropPose75Config &&
        records?.airdropPose75Record
      )
    ) {
      return;
    }
    if (records.airdropPose75Record.who === ethers.constants.AddressZero) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet'));
      return;
    }
    if (!checkTime(configs.airdropPose75Config.startTimestamp.toNumber() * 1000)) {
      message.info(t('The event has not started yet', 'The event has not started yet'));
      return;
    }
    if (records.airdropPose75Record.unlockedAll) {
      message.info(t('You have unlocked all the rewards', 'You have unlocked all the rewards'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(t("You don't have enough BNB for gas to complete the transaction"));
      return;
    }
    try {
      await claimAirdropPose75Write([
        ethers.utils.formatBytes32String(AIRDROP_POSE_NAME[targetName].name75),
      ]);
    } catch (e) {
      console.error('claimAirdropPose75 failed', e);
    }
  }, [
    account,
    airdropPoseContract,
    claimAirdropPose75Write,
    nativeBalance,
    t,
    targetName,
    claimAirdropPose75RecordState.isLoading,
    configs?.airdropPose75Config,
    records?.airdropPose75Record,
  ]);

  // get record for phase3
  const [phase3EstimatedUnlockInfo, setPhase3EstimatedUnlockInfo] = useState();
  const [phase3NeedToSync, setPhase3NeedToSync] = useState(false);
  const [phase3Claimable, setPhase3Claimable] = useState();
  const [phase3UnlockInfo, setPhase3UnlockInfo] = useState();
  const posePrice = usePOSEPrice();
  // console.log("phase3Claimable", formatUnits(phase3Claimable ?? '0', 18))
  // console.log("phase3EstimatedUnlockInfo", phase3EstimatedUnlockInfo)
  // console.log("phase3NeedToSync", phase3NeedToSync)
  // console.log("phase3UnlockInfo", phase3UnlockInfo)

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!(airdropPosePhase3Contract && account)) {
          return;
        }
        // to estimate info after sync/activate
        const judgeUnlockInfoForPose = await airdropPosePhase3Contract.judgeUnlockInfoForPose(
          account,
        );
        const needToSyncForPose = await airdropPosePhase3Contract.needToSyncForPose(account);
        const claimable = await airdropPosePhase3Contract.calcClaimForPose(account);
        const unlockInfoForPose = await airdropPosePhase3Contract.getUnlockInfoForPose(account);

        setPhase3EstimatedUnlockInfo(judgeUnlockInfoForPose);
        setPhase3NeedToSync(needToSyncForPose);
        setPhase3Claimable(claimable);
        setPhase3UnlockInfo(unlockInfoForPose);
      } catch (error) {
        console.error('fetch phase3 info failed', error);
      }
    };
    fetch();
  }, [account, airdropPosePhase3Contract, updateByTimer]);

  // activateForPose
  const { state: phase3ActivateForPoseState, write: phase3ActivateForPoseWrite } = useContract(
    airdropPosePhase3Contract,
    'activateForPose',
  );
  const phase3ActivateForPose = useCallback(async () => {
    if (
      !(
        !phase3ActivateForPoseState.isLoading &&
        posePrice &&
        phase3EstimatedUnlockInfo?.level &&
        phase3UnlockInfo?.level
      )
    ) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (!phase3UnlockInfo.level.isZero()) {
      message.info(t('Already activated', 'Already activated'));
      return;
    }
    if (phase3EstimatedUnlockInfo.level?.isZero()) {
      message.info(t('Not eligible to activate', 'Not eligible to activate'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(t("You don't have enough BNB for gas to complete the transaction"));
      return;
    }
    try {
      await phase3ActivateForPoseWrite([posePrice]);
    } catch (error) {
      console.log('activate failed', error);
    }
  }, [
    account,
    nativeBalance,
    phase3ActivateForPoseState.isLoading,
    phase3ActivateForPoseWrite,
    phase3EstimatedUnlockInfo?.level,
    phase3UnlockInfo?.level,
    posePrice,
    t,
  ]);
  // syncUnlockForPose
  const { state: phase3SyncUnlockForPoseState, write: phase3SyncUnlockForPoseWrite } = useContract(
    airdropPosePhase3Contract,
    'syncUnlockForPose',
  );
  const phase3SyncUnlockForPose = useCallback(async () => {
    if (phase3SyncUnlockForPoseState.isLoading || !posePrice) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (!phase3NeedToSync) {
      message.info(t('Not eligible to update', 'Not eligible to update'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(t("You don't have enough BNB for gas to complete the transaction"));
      return;
    }
    try {
      await phase3SyncUnlockForPoseWrite([account, posePrice]);
    } catch (error) {
      console.log('sync failed', error);
    }
  }, [
    account,
    nativeBalance,
    phase3NeedToSync,
    phase3SyncUnlockForPoseState.isLoading,
    phase3SyncUnlockForPoseWrite,
    posePrice,
    t,
  ]);
  // claimForPose
  const { state: phase3ClaimForPoseState, write: phase3ClaimForPoseWrite } = useContract(
    airdropPosePhase3Contract,
    'claimForPose',
  );
  const phase3ClaimForPose = useCallback(async () => {
    if (phase3ClaimForPoseState.isLoading || !phase3Claimable) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (phase3Claimable.isZero()) {
      message.info(t('Nothing to claim', 'Nothing to claim'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(t("You don't have enough BNB for gas to complete the transaction"));
      return;
    }
    try {
      phase3ClaimForPoseWrite();
    } catch (error) {
      console.log('claim failed');
    }
  }, [
    account,
    nativeBalance,
    phase3ClaimForPoseState.isLoading,
    phase3ClaimForPoseWrite,
    phase3Claimable,
    t,
  ]);

  return {
    configs,
    records,
    targetName,
    setTargetName: setTargetNameHandler,
    claimAirdropPose25Record,
    claimAirdropPose25RecordState,
    claimAirdropPose75Record,
    claimAirdropPose75RecordState,
    airdropPose25Claimable,
    airdropPose75Claimable,
    // phase3
    phase3EstimatedUnlockInfo,
    phase3NeedToSync,
    phase3Claimable,
    phase3UnlockInfo,
    phase3ActivateForPose,
    phase3ActivateForPoseState,
    phase3SyncUnlockForPose,
    phase3SyncUnlockForPoseState,
    phase3ClaimForPose,
    phase3ClaimForPoseState,
  };
};

export const ProvideAirdropPose = ({ children }) => {
  const value = useProvideAirdropPose();
  return <airdropPoseContext.Provider value={value}>{children}</airdropPoseContext.Provider>;
};

ProvideAirdropPose.propTypes = {
  children: PropTypes.node.isRequired,
};
