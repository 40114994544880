import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import styles from './CustomTooltip.module.scss';

const CustomTooltip = (props) => {
  const { tipClassName, title, children, ...rest } = props;
  return (
    <Tooltip
      color="#EDEDED"
      title={<div className={classNames(styles.tip, tipClassName)}>{title}</div>}
      overlayStyle={{ maxWidth: '400px', width: 'fit-content' }}
      // eslint-disable-next-line
      {...rest}>
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  tipClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line
  title: PropTypes.any.isRequired,
};

CustomTooltip.defaultProps = {
  tipClassName: '',
};
export default CustomTooltip;
