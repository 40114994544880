import { ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_REFERRAL_CODE } from '../constants/misc';
import { useWallet } from '../store/wallet-context';
import {
  useContract,
  useInvitationCenterContract,
  useMulticallContract,
  useZepochNodeContract,
} from './useContract';
import { useUpdate } from './useUpdate';
import { multicallHelper } from '../utils/multicall';
import InvitationCenterInterface from '../abis/InvitationCenterInterface.json';
import HERC721IMInterface from '../abis/HERC721IMInterface.json';

export const useIsRegistered = (address = null) => {
  const invitationContract = useInvitationCenterContract();
  const { account } = useWallet();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(invitationContract, 'isRegistered', null, false);

  useEffect(() => {
    const fetchData = async () => {
      if (!(invitationContract && ((address && ethers.utils.isAddress(address)) || account))) {
        return;
      }
      read([address || account]);
    };
    fetchData();
  }, [invitationContract, account, address, updateByTimer, read]);

  return state;
};

export const useRegisteredInvitationCodeContains = () => {
  const invitationContract = useInvitationCenterContract();

  const isValidInvitation = async (code) => {
    if (!(code && DEFAULT_REFERRAL_CODE)) {
      return false;
    }
    try {
      const result = await invitationContract.registeredInvitationCodeContains(
        ethers.utils.formatBytes32String(code),
      );
      return DEFAULT_REFERRAL_CODE === code || result;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return { isValidInvitation };
};

export const useInviterRecords = (address = null) => {
  const decodeInviterRecords = useCallback(
    (record) => ({
      who: record.who,
      inviter: record.inviter,
      // padding with 28 zero bytes
      invitationCode: ethers.utils.parseBytes32String(record.invitationCode),
    }),
    [],
  );

  const toNumber = useCallback((number) => number.toNumber(), []);

  const invitationContract = useInvitationCenterContract();
  const multicallContract = useMulticallContract();
  const zepochNodeContract = useZepochNodeContract();

  const { account } = useWallet();
  const { updateByTimer } = useUpdate();

  const { state: inviterRecords, read: readInviterRecords } = useContract(
    invitationContract,
    'inviterRecords',
    decodeInviterRecords,
    {},
  );
  const { state: inviteeRecordsLength, read: readInviteeRecordsLength } = useContract(
    invitationContract,
    'inviteeRecordsLength',
    toNumber,
    0,
  );

  const [inviteeRecords, setInviteeRecords] = useState();
  const [inviteeZepochBalance, setInviteeZepochBalance] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !(
            zepochNodeContract &&
            multicallContract &&
            invitationContract &&
            inviteeRecordsLength?.data &&
            ((address && ethers.utils.isAddress(address)) || account)
          )
        ) {
          return;
        }
        // multicall to get invitee addresses
        const inviteeRecordsCall = [...Array(inviteeRecordsLength.data).keys()].map((item) => [
          invitationContract.address,
          'inviteeRecordsAt',
          [address || account, item],
        ]);
        const inviteeRecordsRes = (
          await multicallHelper(
            multicallContract,
            InvitationCenterInterface.abi,
            inviteeRecordsCall,
          )
        ).flat();
        const zepochBalanceCall = inviteeRecordsRes.map((item) => [
          zepochNodeContract.address,
          'balanceOf',
          [item],
        ]);
        const zepochBalance = (
          await multicallHelper(multicallContract, HERC721IMInterface.abi, zepochBalanceCall)
        )
          .flat()
          .map((item) => item.toNumber());
        setInviteeZepochBalance(zepochBalance);
        setInviteeRecords(inviteeRecordsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [
    account,
    address,
    invitationContract,
    inviteeRecordsLength.data,
    multicallContract,
    updateByTimer,
    zepochNodeContract,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (!(invitationContract && ((address && ethers.utils.isAddress(address)) || account))) {
        return;
      }
      readInviterRecords([address || account]);
      readInviteeRecordsLength([address || account]);
    };
    fetchData();
  }, [
    invitationContract,
    account,
    address,
    updateByTimer,
    readInviterRecords,
    readInviteeRecordsLength,
  ]);

  return { inviterRecords, inviteeRecordsLength, inviteeRecords, inviteeZepochBalance  };
};

export const useInviterCodeToAddress = (inviterCode) => {
  const invitationContract = useInvitationCenterContract();
  const { state, read } = useContract(invitationContract, 'inviterCodeToAddress', null, null);
  useEffect(() => {
    const fetchData = () => {
      if (!(invitationContract && inviterCode)) {
        return;
      }
      read([ethers.utils.formatBytes32String(inviterCode)]);
    };
    fetchData();
  }, [read, invitationContract, inviterCode]);
  return state;
};
