import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNFTRecords } from '../../hooks/useNFT';
import { useZepochNodeTotalSupply } from '../../hooks/useToken';

import useGetGasShareAmount from '../../hooks/useGasShareAmount';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import InfoItem from '../../components/TokenPool/InfoItem/InfoItem';
import Card from '../../components/UI/Card/Card';
import CustomButton from '../../components/UI/CustomButton/CustomButton';

import styles from './NautilusGasShare.module.scss';
import { formatNumber } from '../../utils/utils';

const NautilusGasShare = () => {
  const { t } = useTranslation();
  const { totalAmountInValue, personalAmountInValue, incrementInValue, personalIncrementInValue } =
    useGetGasShareAmount();

  // Total amount of sold Node
  const { data: zepochNodeTotalSupply } = useZepochNodeTotalSupply();

  // User's amount of node
  const { data: nftRecords } = useNFTRecords();
  const { zepochNodeRecords } = nftRecords;
  const userHoldNodeAmount = zepochNodeRecords?.length;

  const infoList = useMemo(
    () => [
      {
        title: t('Total Gas Pool', 'Total Gas Pool'),
        value: `${formatNumber(totalAmountInValue, '0,0.00')} ZBC`,
      },
      {
        title: t('Total Zepoch Amount', 'Total Zepoch Amount'),
        value: zepochNodeTotalSupply || '-',
      },
      {
        title: t('Gas share(24 hours)', 'Gas share(24 hours)'),
        value: `${formatNumber(incrementInValue, '0,0.00')} ZBC`,
      },
      {
        title: t('Your gas share(24 hours)', 'Your gas share(24 hours)'),
        value: `${formatNumber(personalIncrementInValue, '0,0.00')} ZBC`,
      },
      {
        title: t('Your Zepoch Amount', 'Your Zepoch Amount'),
        value: userHoldNodeAmount || '-',
      },
      {
        title: t('Your Estimated Rewards', 'Your Estimated Rewards'),
        value: `${formatNumber(personalAmountInValue, '0,0.00')} ZBC`,
      },
    ],
    [
      t,
      totalAmountInValue,
      zepochNodeTotalSupply,
      incrementInValue,
      personalIncrementInValue,
      userHoldNodeAmount,
      personalAmountInValue,
    ],
  );

  return (
    <MainContainer className={styles.GasShare}>
      <Card className={styles.poolCard}>
        <div className={styles.cardHeader}>
          <div className={styles.title}>
            <h2>{t('Gas Rewards', 'Gas Rewards')}</h2>
          </div>
          <img className={styles.tokenLogo} src="/static/images/Icons/zbc.png" alt="tokens" />
        </div>
        <hr />
        <div className={styles.infoWrap}>
          {infoList.map((item) => (
            <InfoItem title={item.title} value={item.value} key={item.title} />
          ))}
        </div>
        <div className={styles.actionWrap}>
          <CustomButton
            className={styles.button}
            secondary
            href="https://horizon.nautchain.xyz/gasShare"
            // onClick={handleUnlock}
            // loading={unlockState.isLoading}
          >
            {t('Claim', 'Claim')}
          </CustomButton>
        </div>
      </Card>
    </MainContainer>
  );
};

export default NautilusGasShare;
