import React, { useState, useEffect, useContext, createContext, useCallback } from 'react';
import { message } from 'antd';
import detectEthereumProvider from '@metamask/detect-provider';
import PropTypes from 'prop-types';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';

import { toHex } from '../utils/utils';
import { WALLET_INFO, WALLET_TYPE } from '../constants/wallets';
import { CHAINS } from '../constants/chains';

const TARGET_CHAIN = process.env.REACT_APP_DEV_CONTRACT ? CHAINS.BSC_TEST : CHAINS.BSC;

const DEFAULT_RPC = TARGET_CHAIN.rpc[0];

const walletContext = createContext();

export const useWallet = () => useContext(walletContext);

// Provider hook that creates auth object and handles state
function useProvideWallet() {
  const [account, setAccount] = useState(null);
  const [ethersProvider, setEthersProvider] = useState(
    new ethers.providers.JsonRpcProvider(DEFAULT_RPC),
  );
  const [walletMenuVisible, setWalletMenuVisible] = useState(false);

  const toggleWalletMenuVisible = () => {
    setWalletMenuVisible((prev) => !prev);
  };

  const resetDefault = useCallback(() => {
    setAccount(null);
    setEthersProvider(new ethers.providers.JsonRpcProvider(DEFAULT_RPC));
    sessionStorage.removeItem('wallet');
    window.location.reload();
  }, []);

  const disconnect = async () => {
    if (ethersProvider?.provider?.disconnect) {
      await ethersProvider.provider.disconnect();
    } else {
      resetDefault();
    }
  };

  // connect to chain
  const connect = useCallback(
    async (wallet = WALLET_TYPE.METAMASK) => {
      // helper: verify current chain with target
      const verifyChain = async (provider) => {
        // const chainId = parseInt(await provider.request({ method: 'eth_chainId' }), 16);
        const chainId = Number(await provider.request({ method: 'eth_chainId' }));
        // console.log('current chainId:', chainId);
        return chainId === TARGET_CHAIN.chainId;
      };
      // handle chain change
      const handleChainChanged = async (provider) => {
        // add/switch chain to target chain
        const chainVerified = await verifyChain(provider);
        if (!chainVerified) {
          const networkData = {
            chainId: toHex(TARGET_CHAIN.chainId),
            chainName: TARGET_CHAIN.name,
            nativeCurrency: TARGET_CHAIN.nativeCurrency,
            rpcUrls: TARGET_CHAIN.rpc,
            blockExplorerUrls: [TARGET_CHAIN.explorers[0].url],
          };
          console.log('networkData', networkData);

          try {
            await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: toHex(TARGET_CHAIN.chainId) }],
            });
          } catch (switchError) {
            console.log('switchError', switchError);
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              try {
                await provider.request({
                  method: 'wallet_addEthereumChain',
                  params: [networkData],
                });
              } catch (addError) {
                // handle "add" error
                console.log('addError', addError);
              }
            }
            // handle other "switch" errors
          }
          // verify chain switch
          if (!(await verifyChain(provider))) {
            message.error('Chain Error.');
            throw new Error('Chain switch failed');
          }
        }
      };

      try {
        let ethereumProvider;
        try {
          ethereumProvider = await detectEthereumProvider({ timeout: 1000 });
        } catch (err) {
          console.error(err);
        }
        // handle multi wallets
        let provider;
        switch (wallet) {
          case WALLET_TYPE.WALLETCONNECT:
            provider = new WalletConnectProvider({
              rpc: {
                [TARGET_CHAIN.chainId]: DEFAULT_RPC,
              },
              chainId: TARGET_CHAIN.chainId,
              // network: 'binance',
            });
            //  Enable session (triggers QR Code modal)
            try {
              await provider.enable();
            } catch (err) {
              console.log(err);
            }
            break;
          case WALLET_TYPE.ONTO:
            provider = window.onto || ethereumProvider;
            break;
          default:
            provider = ethereumProvider;
        }

        // handle no provider
        if (!provider) {
          console.log('No provider detected');
          window.open(
            WALLET_INFO[wallet].link,
            '_blank', // <- This is what makes it open in a new window.
          );
          return;
        }

        // unlock wallet
        try {
          await provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
          console.log('eth_requestAccounts failed');
        }

        // add event listener
        if (wallet !== WALLET_TYPE.ONTO) {
          // listen to chainChanged
          provider.on('chainChanged', () => {
            window.location.reload();
          });
          // listen to accountsChanged
          provider.on('accountsChanged', () => {
            window.location.reload();
          });
          provider.on('disconnect', () => {
            resetDefault();
          });
        }

        // change chain
        await handleChainChanged(provider);
        // set ethersProvider
        setEthersProvider(new ethers.providers.Web3Provider(provider));

        // set auto connect
        sessionStorage.wallet = wallet;
      } catch (err) {
        console.error(err);
      }
    },
    [resetDefault],
  );

  useEffect(() => {
    if (sessionStorage.wallet) {
      connect(sessionStorage.wallet);
    }
  }, [connect]);

  useEffect(() => {
    let isMounted = true;
    const getAccounts = async () => {
      let tempAccount = null;
      if (ethersProvider instanceof ethers.providers.Web3Provider) {
        try {
          const accounts = await ethersProvider.send('eth_accounts', []);
          if (accounts.length !== 0) {
            tempAccount = accounts[0].toLowerCase();
          }
        } catch (err) {
          console.error('noWalletConnected', err);
        }
      }
      if (isMounted) {
        setAccount(tempAccount);
      }
    };
    getAccounts();
    return () => {
      isMounted = false;
    };
  }, [ethersProvider]);

  return {
    account,
    connect,
    disconnect,
    ethersProvider,
    walletMenuVisible,
    toggleWalletMenuVisible,
  };
}

export function ProvideWallet({ children }) {
  const wallet = useProvideWallet();
  return <walletContext.Provider value={wallet}>{children}</walletContext.Provider>;
}

ProvideWallet.propTypes = {
  children: PropTypes.node.isRequired,
};
