export const DEV_ADDRESSES = {
  MULTICALL: '0x1Ac73F6eD8fA9215E6AAdc1cdc057f4eaA275B65',
  NAMESERVICE: '0x5945e09681C83C33665F7fFe53576b4920CA3480',
  USDT: '0x55d398326f99059fF775485246999027B3197955',
  ZBC: '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
};

export const PROD_ADDRESSES = {
  MULTICALL: '0xbb931CC8c3cCbf3009AbDD4096327f0c8D572AB1',
  NAMESERVICE: '0x9D2076fDE46EeB5F335B7C8523b3d4a5641EeD9e',
  USDT: '0x55d398326f99059fF775485246999027B3197955',
  ZBC: '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
};

export const ADDRESSES = process.env.REACT_APP_DEV_CONTRACT ? DEV_ADDRESSES : PROD_ADDRESSES;

export const CONTRACT_NAME = {
  InvitationCenter: 'InvitationCenter',
  ZBC: 'ZBCBridged',
  ZBCLegacy: 'ZBCLegacy',
  USDT: 'USDT',
  BUSD: 'BUSD',
  USDC: 'USDC',
  ZPoint: 'ZPoint', // ERC20IMPROVED
  ZepochNode: 'ZepochNode', // ERC721IMPROVED
  ThemisGavel: 'ThemisGavel', // ERC721IMPROVED
  Manager: 'Manager',
  Vault: 'Vault',
  Pool: 'Pool',
  LinearUnlock: 'LinearUnlock',
  AirdropPose: 'AirdropPose',
  AirdropPosePhase3: 'AirdropPosePhase3',
};

export const NULL_ADDR = '0x0000000000000000000000000000000000000000';

export const SUPPORT_PURCHASE_TOKEN_KEY = {
  1: [CONTRACT_NAME.ZBCLegacy, CONTRACT_NAME.USDT, CONTRACT_NAME.USDC],
  2: [
    CONTRACT_NAME.USDT,
    CONTRACT_NAME.USDC,
    // , CONTRACT_NAME.ZBC
  ],
};

export const LP_TOKEN_DECIMAL = 18;

export const TOKEN_POOL_INDEX = 0;

export const BLACK_LIST = [
  '0xfabef439f37c86b081a19ea3e5dc303673e6edff',
  '0x6283f5acedd2f60cc6309a01bc7fa0d2bb1182a7',
  '0x8cc11655aaa45ce0e09377b8883c45ab01288472',
  '0x535c32a0fea4c3090b9b829bdd1c11eaf6f75ac7',
  '0xf30eb199605721caaae30ac420ad146980ec76be',
  '0x7ea48fb686874a46ebad4fd06b9364b52e3c8176',
  '0xe2913dd80fdbe40a02301384dc63722ca7a9d19f',
  '0xf73f84e9d8415673ac24be2e4d78faf615f7fb1d',
  '0x6c56dc570f70a3d572839204dd9e2269f5b9f188',
  '0x33846c72ec46a0d01164431ee9e18e3ca63e3ca8',
];
