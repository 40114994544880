import React, { useState } from 'react';
import classNames from 'classnames';
import { message } from 'antd';
import { parseUnits } from 'ethers/lib/utils';
import { useTranslation } from 'react-i18next';

import CustomInput from '../../components/UI/CustomInput/CustomInput';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import { useAllTokenAllowance, useAllTokenBalance, useTokenApprove } from '../../hooks/useToken';
import { CONTRACT_NAME } from '../../constants/addresses';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import { useNameservice } from '../../store/nameservice-context';
import { formatNumber } from '../../utils/utils';
import { useWallet } from '../../store/wallet-context';

import styles from './Swap.module.scss';
import commonStyles from '../../common.module.scss';
import { useExchange } from '../../hooks/useManager';

const Swap = () => {
  const { t } = useTranslation();
  const { account } = useWallet();

  const [oldTokenAmount, setOldTokenAmount] = useState('');

  // get allowance
  const addresses = useNameservice();
  const allTokenAllowance = useAllTokenAllowance(addresses[CONTRACT_NAME.Manager]);
  const { data: allowance } = allTokenAllowance[CONTRACT_NAME.ZBCLegacy];
  const { state: approveTokenState, approve } = useTokenApprove(addresses[CONTRACT_NAME.ZBCLegacy]);
  // get balance
  const allTokenBalance = useAllTokenBalance();

  const { data: zbcBalance } = allTokenBalance[CONTRACT_NAME.ZBCLegacy];
  const { data: zbcNewBalance } = allTokenBalance[CONTRACT_NAME.ZBC];
  const { data: nativeBalance } = allTokenBalance.nativeBalance;

  const { state: exchangeState, exchange } = useExchange();

  const handleOldTokenAmountChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value.length === 0) {
      setOldTokenAmount(value);
      return;
    }
    try {
      parseUnits(value, 9);
      setOldTokenAmount(value);
    } catch (error) {
      console.log('invalid input');
    }
  };

  const handleSwap = async () => {
    if (exchangeState.isLoading || !zbcBalance) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (!oldTokenAmount) {
      message.info(t('Please enter a valid amount', 'Please enter a valid amount'));
      return;
    }
    const amount = parseUnits(oldTokenAmount, 9);
    if (amount.gt(parseUnits(zbcBalance.toString(), 9))) {
      message.info(
        t('Not enough currency', 'Not enough {{currency}}', { currency: CONTRACT_NAME.ZBCLegacy }),
      );
      return;
    }
    try {
      await exchange(amount);
    } catch (e) {
      console.log('swap failed');
    }
  };

  const handleSwapAll = async () => {
    if (exchangeState.isLoading || !zbcBalance) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    const amount = parseUnits(zbcBalance.toString(), 9);
    if (!amount.gt(0)) {
      message.info(
        t('Not enough currency', 'Not enough {{currency}}', { currency: CONTRACT_NAME.ZBCLegacy }),
      );
      return;
    }
    console.log('swap all');

    try {
      await exchange(0);
    } catch (e) {
      console.log('swap failed');
    }
  };

  const handleApprove = () => {
    if (approveTokenState.isLoading || !addresses[CONTRACT_NAME.Manager]) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance < 0.01) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    try {
      approve(addresses[CONTRACT_NAME.Manager]);
    } catch (error) {
      console.log(approve);
    }
  };

  return (
    <div className={styles.Swap}>
      <MainContainer className={styles.mainContent}>
        <div className={styles.swapWrap}>
          <div className={styles.descWrap}>
            <h2 className={classNames(commonStyles.gradientTitle, styles.swapTitle)}>
              {t('Swap', 'Swap')}
            </h2>
          </div>
          <div className={styles.actionWrap}>
            <div className={styles.balance}>
              <p>{t('Balance', 'Balance')}</p>
              <p>{formatNumber(zbcBalance)}</p>
            </div>
            <div className={styles.input}>
              <CustomInput value={oldTokenAmount} onChange={handleOldTokenAmountChange} />
              <div className={styles.suffix}>ZBC Legacy</div>
            </div>
            <img className={styles.arrow} src="/static/images/Swap/downArrow.svg" alt="down" />
            <div className={styles.balance}>
              <p>{t('Balance', 'Balance')}</p>
              <p>{formatNumber(zbcNewBalance)}</p>
            </div>
            <div className={styles.input}>
              <CustomInput value={oldTokenAmount} disabled />
              <div className={styles.suffix}>ZBC</div>
            </div>
            {allowance > Number(oldTokenAmount) ? (
              <>
                <CustomButton
                  rounded
                  className={styles.button}
                  onClick={handleSwap}
                  loading={exchangeState.isLoading}>
                  {t('Swap', 'Swap')}
                </CustomButton>
                <CustomButton
                  rounded
                  className={styles.button}
                  onClick={handleSwapAll}
                  loading={exchangeState.isLoading}>
                  {t('Swap All', 'Swap All')}
                </CustomButton>
              </>
            ) : (
              <CustomButton
                rounded
                className={styles.button}
                onClick={handleApprove}
                loading={approveTokenState.isLoading}>
                {t('APPROVE', 'APPROVE')} ZBC
              </CustomButton>
            )}
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default Swap;
