import { ethers } from 'ethers';
import { useState, useCallback } from 'react';
import { useWallet } from '../store/wallet-context';

import axios from '../utils/axios';
import { useIsMounted } from './useIsMounted';

const RANK_LENGTH = 10;

export function usePoints() {
  const [rankList, setRankList] = useState([]);

  const [myRanking, setMyRanking] = useState({});

  const { account } = useWallet();

  const isMounted = useIsMounted();

  const getPointsRanking = useCallback(() => {
    axios
      .get('manage/points/now')
      .then((res) => {
        let records;
        if (account) {
          records = res.data.data?.list?.map((item) => ({
            ...item,
            isCurrentUser: item.address.toLowerCase() === account,
          }));
        } else {
          records = res.data.data?.list;
        }
        if (!isMounted.current) {
          return;
        }
        records = records || [];
        records = records.filter((item) => item.points !== '0');

        const diff = RANK_LENGTH - records.length;

        for (let i = 0; i < diff; i += 1) {
          records.push({ id: `placeholder${i}` });
        }
        setRankList(records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [account, isMounted]);

  const getMyRanking = useCallback(
    (address = null) => {
      if (!ethers.utils.isAddress(address || account)) {
        return;
      }
      axios
        .get(`manage/points/ranking/${address || account}`)
        .then((res) => {
          if (!isMounted.current) {
            return;
          }
          setMyRanking(res.data.data || {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [account, isMounted],
  );

  return {
    rankList,
    myRanking,
    setRankList,
    getPointsRanking,
    getMyRanking,
  };
}
