import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import classNames from 'classnames';

import styles from './CustomModal.module.scss';

const CustomModal = (props) => {
  const { children, className, title, open, width, onCancel, showTitle, ...rest } = props;
  return (
    <Modal
      wrapClassName={classNames(styles.CustomModal, className)}
      open={open}
      width={width}
      onCancel={onCancel}
      destroyOnClose
      centered
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(28,28,28,0.7)' }}
      // eslint-disable-next-line
      {...rest}>
      {showTitle && (
        <div className={styles.titleWrap}>
          <h2>{title}</h2>
          <img src="/static/images/Icons/close.svg" alt="close" onClick={onCancel} />
        </div>
      )}
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  width: PropTypes.number,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
};

CustomModal.defaultProps = {
  children: null,
  className: '',
  title: '',
  open: false,
  width: 450,
  onCancel: () => {},
  showTitle: false,
};

export default CustomModal;
