/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';

// eslint-disable-next-line no-unused-vars
import styles from './CustomInput.module.scss';

const CustomInput = ({ placeholder, value, onChange, disabled }) => (
  <Input placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} />
);

export default CustomInput;
