import React, { useCallback, useMemo, useState } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { constants } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import HashLinkWithQuery from '../../../components/HashLinkWithQuery/HashLinkWithQuery';
import InfoItem from '../../../components/TokenPool/InfoItem/InfoItem';
import Card from '../../../components/UI/Card/Card';
import CustomButton from '../../../components/UI/CustomButton/CustomButton';
import MainContainer from '../../../components/UI/MainContainer/MainContainer';
import CustomModal from '../../../components/UI/CustomModal/CustomModal';
import CustomInput from '../../../components/UI/CustomInput/CustomInput';

import { CONTRACT_NAME, LP_TOKEN_DECIMAL, TOKEN_POOL_INDEX } from '../../../constants/addresses';
import { useNameservice } from '../../../store/nameservice-context';
import { useStake, useTokenPool, useWithdraw } from '../../../hooks/useTokenPool';
import { useWallet } from '../../../store/wallet-context';
import { formatNumber } from '../../../utils/utils';
import { useTokenAllowance, useTokenApprove, useTokenBalance } from '../../../hooks/useToken';
import { useIsRegistered } from '../../../hooks/useInvitationCenter';

import styles from './TokenPoolAction.module.scss';

const ACTION = { STAKE: 0, WITHDRAW: 1 };

const TokenPoolAction = () => {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState({ visable: false, action: ACTION.STAKE });
  const [tokenAmount, setTokenAmount] = useState('');

  // token pool list
  const { [CONTRACT_NAME.Pool]: tokenPoolAddresses } = useNameservice();

  const currentPoolAddress = tokenPoolAddresses?.[TOKEN_POOL_INDEX];

  // pool config and person record
  const {
    configState: { data: config },
    recordsState: { data: record },
  } = useTokenPool();

  // lp token address, for balance and allowance
  const { sourceAddress: lpTokenAddress } = config;

  // lp token balance in wallet
  const { data: lpTokenBalance } = useTokenBalance(lpTokenAddress, LP_TOKEN_DECIMAL, null, false);
  // nativeBalance
  const { data: nativeBalance } = useTokenBalance(constants.AddressZero, 18, null, false);
  // lp token allowance
  const { data: lpTokenAllowance } = useTokenAllowance(
    lpTokenAddress,
    currentPoolAddress,
    LP_TOKEN_DECIMAL,
    null,
    false,
  );

  const { data: isRegistered } = useIsRegistered();

  const { state: approveLpTokenState, approve: approveLpToken } = useTokenApprove(lpTokenAddress);
  const { state: stakeState, stake } = useStake();
  const { state: withdrawState, withdraw } = useWithdraw();

  const { account, toggleWalletMenuVisible } = useWallet();

  const calcShare = useCallback((part, total) => {
    if (!(part && total && !part.isZero() && !total.isZero())) {
      return '0';
    }
    return formatUnits(part.mul(constants.WeiPerEther).div(total), LP_TOKEN_DECIMAL);
  }, []);

  const handleTokenAmountChange = (value) => {
    if (value.length === 0) {
      setTokenAmount(value);
      return;
    }
    try {
      parseUnits(value, LP_TOKEN_DECIMAL);
      setTokenAmount(value);
    } catch (error) {
      console.log('invalid input');
    }
  };

  const openStakeModal = (action) => {
    setModalState({ visable: true, action });
  };

  const closeStakeModal = () => {
    setTokenAmount('');
    setModalState((props) => ({ ...props, visable: false }));
  };

  const handleStake = useCallback(async () => {
    if (stakeState.isLoading || !tokenAmount || !lpTokenBalance) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    if (!isRegistered) {
      message.info(
        t('register-warning', 'Please register your account by purchasing the Zepoch Node'),
      );
      return;
    }
    if (parseUnits(tokenAmount, LP_TOKEN_DECIMAL).gt(lpTokenBalance)) {
      message.info(t('Not enough ZBC-USDT LP Token', 'Not enough ZBC-USDT LP Token'));
      return;
    }
    try {
      await stake(parseUnits(tokenAmount, LP_TOKEN_DECIMAL));
    } catch (e) {
      console.log('stake failed', e);
    }
    closeStakeModal();
  }, [
    tokenAmount,
    account,
    nativeBalance,
    stake,
    stakeState.isLoading,
    t,
    lpTokenBalance,
    isRegistered,
  ]);

  const handleWithdraw = useCallback(async () => {
    if (withdrawState.isLoading || !tokenAmount || !record.stakedSourceAmount) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    if (parseUnits(tokenAmount, LP_TOKEN_DECIMAL).gt(record.stakedSourceAmount)) {
      message.info(t('Not enough ZBC-USDT LP Token', 'Not enough ZBC-USDT LP Token'));
      return;
    }
    try {
      await withdraw(parseUnits(tokenAmount, LP_TOKEN_DECIMAL));
    } catch (e) {
      console.log('withdraw failed', e);
    }
    closeStakeModal();
  }, [
    tokenAmount,
    account,
    nativeBalance,
    withdraw,
    withdrawState.isLoading,
    t,
    record.stakedSourceAmount,
  ]);

  const handleApprove = useCallback(async () => {
    if (approveLpTokenState.isLoading || !currentPoolAddress) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }
    try {
      await approveLpToken(currentPoolAddress);
    } catch (error) {
      console.log(error);
    }
  }, [
    account,
    approveLpToken,
    approveLpTokenState.isLoading,
    currentPoolAddress,
    nativeBalance,
    t,
  ]);

  const selectAll = (balance) => {
    if (!balance) {
      return;
    }
    handleTokenAmountChange(balance.toString());
  };

  const infoList = useMemo(
    () => [
      {
        title: t('Total LP Staked', 'Total LP Staked'),
        value: formatNumber(
          formatUnits(config.totalSupplySource || constants.Zero, LP_TOKEN_DECIMAL),
        ),
      },
      {
        title: t('Your LP Staked', 'Your LP Staked'),
        value: formatNumber(
          formatUnits(record.stakedSourceAmount || constants.Zero, LP_TOKEN_DECIMAL),
        ),
      },
      {
        title: t('Share in Pool', 'Share in Pool'),
        value: formatNumber(
          calcShare(record.stakedSourceAmount, config.totalSupplySource),
          '0.[0000]%',
        ),
      },
    ],
    [config.totalSupplySource, record.stakedSourceAmount, calcShare, t],
  );

  const modalConfigs = useMemo(
    () => ({
      [ACTION.STAKE]: {
        title: `${t('Stake')} ZBCUSDT LP`,
        buttonText: t('Stake'),
        handler: handleStake,
        handlerState: stakeState,
        balance: lpTokenBalance,
      },
      [ACTION.WITHDRAW]: {
        title: `${t('Withdraw', 'Withdraw')} ZBCUSDT LP`,
        buttonText: t('Withdraw'),
        handler: handleWithdraw,
        handlerState: withdrawState,
        balance: record.stakedSourceAmount,
      },
    }),
    [
      handleStake,
      handleWithdraw,
      lpTokenBalance,
      record.stakedSourceAmount,
      stakeState,
      withdrawState,
      t,
    ],
  );

  const currentConfig = modalConfigs[modalState.action];

  const actionWrapContent = useMemo(() => {
    if (!account) {
      return (
        <CustomButton className={styles.button} onClick={toggleWalletMenuVisible}>
          {t('Connect Wallet', 'Connect Wallet')}
        </CustomButton>
      );
    }
    return (
      <>
        <CustomButton
          className={styles.button}
          onClick={() => openStakeModal(ACTION.STAKE)}
          loading={stakeState.isLoading}>
          {t('Stake')}
        </CustomButton>
        <CustomButton
          className={classNames(styles.button, styles.remove)}
          secondary
          onClick={() => openStakeModal(ACTION.WITHDRAW)}
          loading={withdrawState.isLoading}>
          {t('Withdraw', 'Withdraw')}
        </CustomButton>
      </>
    );
  }, [account, toggleWalletMenuVisible, stakeState.isLoading, withdrawState.isLoading, t]);

  return (
    <>
      <CustomModal open={modalState.visable} onCancel={closeStakeModal}>
        <div className={styles.stakeModalContent}>
          <h2 className={styles.stakeModalTitle}>{currentConfig?.title}</h2>
          <div className={styles.stakeModalBalanceWrap}>
            <span>Balance:</span>
            <span>
              {formatNumber(
                formatUnits(currentConfig?.balance || constants.Zero, LP_TOKEN_DECIMAL),
              )}{' '}
              ZBC-USDT
            </span>
          </div>
          <div className={styles.stakeModalInputWrap}>
            <CustomInput
              value={tokenAmount}
              onChange={(e) => {
                handleTokenAmountChange(e.target.value);
              }}
            />
            <span
              className={styles.selectMax}
              onClick={() => {
                selectAll(formatUnits(currentConfig?.balance, LP_TOKEN_DECIMAL));
              }}>
              Max
            </span>
          </div>
          {modalState.action === ACTION.STAKE &&
          !lpTokenAllowance.gt(parseUnits(tokenAmount || '0', LP_TOKEN_DECIMAL)) ? (
            <CustomButton
              className={styles.stakeModalButton}
              onClick={handleApprove}
              loading={approveLpTokenState.isLoading}>
              {t('Approve Token', 'Approve Token')}
            </CustomButton>
          ) : (
            <CustomButton
              className={classNames(styles.stakeModalButton, {
                [styles.withdraw]: modalState.action === ACTION.WITHDRAW,
              })}
              onClick={currentConfig?.handler}
              loading={currentConfig?.handlerState?.isLoading}>
              {currentConfig?.buttonText}
            </CustomButton>
          )}

          <a
            className={styles.addLiquidity}
            href="https://pancakeswap.finance/add/0x37a56cdcD83Dce2868f721De58cB3830C44C6303/0x55d398326f99059fF775485246999027B3197955?step=1"
            target="_blank"
            rel="noopener noreferrer">
            <img src="/static/images/TokenPool/outsideLlink.svg" alt="link" /> Get ZBC-USDT LP
          </a>
        </div>
      </CustomModal>
      <div className={styles.header}>
        <MainContainer className={styles.headerContainer}>
          <HashLinkWithQuery to="/farm">
            <LeftOutlined
              style={{ fontSize: '28px', color: '#59d89d', stroke: '#59d89d', strokeWidth: '50' }}
            />
          </HashLinkWithQuery>
          <div className={styles.headerTitle}>
            <h2>{t('Your Liquidity', 'Your Liquidity')}</h2>
            {/* <p>{t('Add liquidity to earn more', 'Add liquidity to earn more')}</p> */}
          </div>
        </MainContainer>
      </div>
      <MainContainer className={styles.TokenPoolAction}>
        <Card className={styles.poolCard}>
          <div className={styles.cardHeader}>
            <img
              className={styles.tokenLogo}
              src="/static/images/TokenPool/zbc-usdt.png"
              alt="tokens"
            />
            <div className={styles.title}>
              <h2>ZBC-USDT LP</h2>
            </div>
          </div>
          <hr />
          <div className={styles.infoWrap}>
            {infoList.map((item) => (
              <InfoItem title={item.title} value={item.value} key={item.title} />
            ))}
          </div>
          <div className={styles.actionWrap}>{actionWrapContent}</div>
        </Card>
      </MainContainer>
    </>
  );
};

export default TokenPoolAction;
