/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import styles from './Tab.module.scss';

const TabLabel = ({ children, className, onClick }) => (
  <div className={classNames(styles.tabItem, className)} onClick={onClick}>
    {children}
  </div>
);
export default TabLabel;
