import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavLinkWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NavLink to={{ pathname: to, search }} {...props}>
      {children}
    </NavLink>
  );
};

NavLinkWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavLinkWithQuery;
