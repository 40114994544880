import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, message } from 'antd';
import classNames from 'classnames';
import { usePages } from '../../constants/pages';
import CustomButton from '../UI/CustomButton/CustomButton';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useWallet } from '../../store/wallet-context';
import { truncAddress } from '../../utils/utils';
import NavLinkWithQuery from '../NavLinkWithQuery/NavLinkWithQuery';

import styles from './Header.module.scss';

const langs = [
  { key: 'en', label: 'English' },
  { key: 'zh', label: '简体中文' },
  { key: 'ja', label: '日本語' },
  { key: 'ko', label: '한국의' },
  { key: 'es', label: 'Español' },
  { key: 'ru', label: 'Русский' },
  { key: 'hi', label: 'हिन्दी' },
  { key: 'ar', label: 'عربي' },
  { key: 'ms', label: 'Orang Melayu' },
  { key: 'vi', label: 'tiếng Việt' },
  { key: 'id', label: 'Bahasa Indonesia' },
];

const Header = () => {
  const PAGES = usePages();
  const mainPages = useMemo(() => PAGES.filter((item) => !item.isSubPage), [PAGES]);

  const isMobileView = useIsMobileView();
  const [menuCollapsed, setMenuCollapsed] = useState(isMobileView);
  const { toggleWalletMenuVisible, account } = useWallet();
  const [pages, setPages] = useState([]);
  const [currentLang, setCurrentLang] = useState('en');
  const { t, i18n } = useTranslation();

  const langMenu = (
    <Menu
      items={langs}
      onClick={(item) => {
        i18n.changeLanguage(item.key);
      }}
      className={styles.menu}
    />
  );

  useEffect(() => {
    setPages(mainPages.filter((item) => !item.protected || account));
  }, [account, mainPages]);

  useEffect(() => {
    setCurrentLang(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (isMobileView) {
      setMenuCollapsed(true);
    } else {
      setMenuCollapsed(false);
    }
  }, [isMobileView]);

  const toggleMenu = () => {
    if (isMobileView) {
      setMenuCollapsed(!menuCollapsed);
    }
  };

  return (
    <div className={styles.Header}>
      {isMobileView && (
        <div
          className={classNames(styles.backdrop, { [styles.visible]: !menuCollapsed })}
          onClick={toggleMenu}
        />
      )}
      <NavLinkWithQuery to="/" className={styles.logo}>
        <img src="/static/images/Header/logo.svg" alt="zebec" />
        <h2>Zebec</h2>
      </NavLinkWithQuery>
      {isMobileView && (
        <img
          className={styles.menuButton}
          src="/static/images/Header/menu.svg"
          alt="menu"
          onClick={toggleMenu}
        />
      )}
      {!menuCollapsed && (
        <div className={styles.menuWrap}>
          <div className={styles.navWrap}>
            {pages.map((item) => {
              if (item.href) {
                return (
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.navItem}
                    key={item.text}
                    onClick={toggleMenu}>
                    {t(item.text)}
                  </a>
                );
              }
              if (item.menuItems) {
                const navMenu = (
                  <Menu items={item.menuItems} className={styles.navMenu} onClick={toggleMenu} />
                );
                return (
                  <Dropdown overlay={navMenu} key={item.text}>
                    <div className={styles.navItem}>{t(item.text)}</div>
                  </Dropdown>
                );
              }

              return (
                <NavLinkWithQuery
                  to={item.path}
                  className={(isActive) =>
                    classNames(styles.navItem, { [styles.active]: isActive })
                  }
                  onClick={() => {
                    toggleMenu();
                    if (item.maintenance) {
                      message.info(t('page-under-maintenance', 'The page is under maintenance.'));
                    }
                  }}
                  key={item.text}>
                  {t(item.text)}
                </NavLinkWithQuery>
              );
            })}
          </div>
          <Dropdown overlay={langMenu} trigger="click">
            <img
              className={styles.langButton}
              src={`/static/images/Header/flags/${currentLang}.png`}
              alt={currentLang}
            />
          </Dropdown>
          {account ? (
            <CustomButton
              className={styles.walletButton}
              rounded
              gray
              onClick={() => {
                toggleWalletMenuVisible();
                toggleMenu();
              }}>
              {truncAddress(account)}
            </CustomButton>
          ) : (
            <CustomButton
              className={styles.walletButton}
              rounded
              onClick={() => {
                toggleWalletMenuVisible();
                toggleMenu();
              }}>
              {t('Connect', 'Connect')}
            </CustomButton>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
