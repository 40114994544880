/* eslint-disable react/prop-types */
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import styles from './NumberInput.module.scss';

const regexp = /^-?[0-9]*$/;

const NumberInput = ({ value = 1, onChange } = { value: 1 }) => {
  const handleInputChange = (e) => {
    const {
      target: { value: newValue },
    } = e;

    if (regexp.test(newValue)) {
      onChange?.(newValue ? Number(newValue) : 0);
    }
  };

  return (
    <div className={styles.NumberInput}>
      <div
        className={classNames(styles.NumberButton, styles.NumberButtonLeft)}
        onClick={() => {
          if (value <= 1) return;
          onChange?.(value - 1);
        }}>
        <MinusOutlined style={{ color: '#59d89d', stroke: '#59d89d', strokeWidth: '100' }} />
      </div>
      <input
        className={styles.NumberInputValue}
        type="text"
        value={value}
        onChange={handleInputChange}
      />
      <div
        className={classNames(styles.NumberButton, styles.NumberButtonRight)}
        onClick={() => onChange?.(value + 1)}>
        <PlusOutlined style={{ color: '#59d89d', stroke: '#59d89d', strokeWidth: '100' }} />
      </div>
    </div>
  );
};

export default NumberInput;
