/* eslint-disable react/prop-types */
import React from 'react';
import NavLinkWithQuery from '../NavLinkWithQuery/NavLinkWithQuery';
import styles from './SquareCard.module.scss';

const SquareCard = ({ name, bgImg, logoImg, path, url }) => {
  const content = (
    <div className={styles.square}>
      <div className={styles.card} style={{ backgroundImage: `url('${bgImg}')` }}>
        <div className={styles.cardOverlay}>
          <img className={styles.cardLogo} src={logoImg} alt="logo" />
          <div className={styles.cardContent}>{name}</div>
        </div>
      </div>
    </div>
  );

  if (url) {
    return (
      <a href={url} target="_black" rel="noopener noreferrer">
        {content}
      </a>
    );
  }
  return <NavLinkWithQuery to={path}>{content}</NavLinkWithQuery>;
};
export default SquareCard;
