import axios from 'axios';
import { BigNumber, utils } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { useWallet } from '../store/wallet-context';
import { useIsMounted } from './useIsMounted';
import { useUpdate } from './useUpdate';

export const useTreasuryClaimFomoAmount = () => {
  const isMounted = useIsMounted();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();

  const [claimData, setClaimData] = useState({});

  const fetchClaim = useCallback(async () => {
    let data = {};
    if (!utils.isAddress(account)) {
      return data;
    }
    try {
      const res = await axios.get(`https://api2.zebec.io/claim?address=${account}`);
      if (res.status === 200) {
        data = res.data;
      }
    } catch (error) {
      console.log(error);
    }
    return data;
  }, [account]);

  useEffect(() => {
    fetchClaim().then((res) => {
      if (!Object.keys(res).length || !isMounted.current) {
        return;
      }
      setClaimData({
        who: res.address,
        usdtAmount: BigNumber.from(res.usdtAmount),
        busdAmount: BigNumber.from(res.busdAmount),
        reason: res.reason,
        claimedTimestamp: BigNumber.from(res.timestamp),
        v: res.v,
        r: res.r,
        s: res.s,
      });
    });
  }, [fetchClaim, isMounted, updateByTimer]);
  return claimData;
};
