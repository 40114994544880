import { useEffect, useState } from 'react';
import axios from 'axios';
import { useIsMounted } from './useIsMounted';

const GEOLOCATION_API = 'https://api.country.is/';

export const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState({});
  const isMounted = useIsMounted();
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.get(GEOLOCATION_API);
        if (isMounted) {
          setGeolocation(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [isMounted]);

  return geolocation;
};
