import React from 'react';
import RewardsPoolAction from '../../components/RewardsPool/RewardsPoolAction/RewardsPoolAction';
import MainContainer from '../../components/UI/MainContainer/MainContainer';

import styles from './RewardsPool.module.scss';

const RewardsPool = () => (
  <MainContainer className={styles.mainContainer}>
    <RewardsPoolAction />
  </MainContainer>
);
export default RewardsPool;
