import React from 'react';
import classNames from 'classnames';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import { CONTRACT_NAME } from '../../constants/addresses';
import { useNameservice } from '../../store/nameservice-context';
import { formatOutput } from '../../utils/utils';

import styles from './CustomAlert.module.scss';
import commonStyle from '../../common.module.scss';

const CustomAlert = () => {
  const { [CONTRACT_NAME.ZBC]: zbcAddress } = useNameservice();
  const { t } = useTranslation();
  const alert = (
    <div className={styles.alert}>
      <img className={styles.icon} src="/static/images/CustomAlert/icon.png" alt="icon" />
      <div className={styles.textWrap}>
        <div className={styles.titleWrap}>
          <h2 className={classNames(commonStyle.gradientTitle, styles.addressTitle)}>
            {t('ZBC Address:', 'ZBC Address:')}
          </h2>
          <a href={`https://bscscan.com/address/${zbcAddress}`} className={styles.address}>
            {formatOutput(zbcAddress)}
          </a>
        </div>
        {/* <div className={styles.desc}>
          <Trans i18nKey="alert-desc-with-link">
            The Token Name and Token Symbol issue has been resolved, please use the Swap Page to get
            new ZBC token.
          </Trans>
        </div> */}
      </div>
    </div>
  );
  return (
    <div className={styles.CustomAlert}>
      <Alert showIcon={false} description={alert} banner closable />
    </div>
  );
};

export default CustomAlert;
