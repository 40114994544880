import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';
import { Col, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useWallet } from '../../store/wallet-context';
import CustomButton from '../UI/CustomButton/CustomButton';
import { SUPPORTTED_WALLET, WALLET_INFO } from '../../constants/wallets';
import { useInviterRecords } from '../../hooks/useInvitationCenter';

import styles from './WalletMenu.module.scss';

const WalletMenu = (props) => {
  const { className } = props;
  const { walletMenuVisible, toggleWalletMenuVisible, connect, account, disconnect } = useWallet();
  const { inviterRecords } = useInviterRecords();
  const { t } = useTranslation();

  const {
    data: { invitationCode },
  } = inviterRecords;

  const handleConnectWallet = (walletType) => {
    toggleWalletMenuVisible();
    connect(walletType);
  };

  const getConnectMenu = () => (
    <div
      className={classNames(styles.WalletMenu, className, {
        [styles.visible]: walletMenuVisible,
      })}>
      <h2 className={styles.title}>
        {t('Select a wallet to continue', 'Select a wallet to continue')}
      </h2>
      <Row gutter={[16, 16]}>
        {SUPPORTTED_WALLET.map((item) => (
          <Col span={12} key={item}>
            <div
              className={styles.MenuItem}
              onClick={() => {
                handleConnectWallet(item);
              }}>
              <img src={`/static/images/Wallets/${item}.png`} alt={item} />
              <h2>{WALLET_INFO[item].name}</h2>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  const getDisconnectMenu = () => (
    <div
      className={classNames(styles.WalletMenu, styles.disconnectMenu, className, {
        [styles.visible]: walletMenuVisible,
      })}>
      <h2 className={styles.title}> {t('Connected Wallet', 'Connected Wallet')}</h2>
      {!!invitationCode && (
        <>
          <CustomButton rounded>{invitationCode}</CustomButton>
          <CopyToClipboard
            text={invitationCode}
            onCopy={() => {
              message.success(t('Referral Code Copied', 'Referral Code Copied'));
            }}>
            <CustomButton secondary>Copy Referral Code</CustomButton>
          </CopyToClipboard>
        </>
      )}
      <h2
        className={styles.disconnectButton}
        onClick={() => {
          toggleWalletMenuVisible();
          disconnect();
        }}>
        {t('Disconnect', 'Disconnect')}
      </h2>
    </div>
  );

  return (
    <>
      <div
        className={classNames(styles.backdrop, { [styles.visible]: walletMenuVisible })}
        onClick={toggleWalletMenuVisible}
      />
      {account ? getDisconnectMenu() : getConnectMenu()}
    </>
  );
};

WalletMenu.propTypes = {
  className: PropTypes.string,
};

WalletMenu.defaultProps = {
  className: '',
};

export default WalletMenu;
