import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InfoItem.module.scss';

const InfoItem = (props) => {
  const { title, value, className } = props;
  return (
    <div className={classNames(styles.InfoItem, className)}>
      <span className={styles.title}>{title}</span> <span className={styles.value}>{value}</span>
    </div>
  );
};

InfoItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

InfoItem.defaultProps = {
  className: '',
};

export default InfoItem;
