import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NavLinkWithQuery from '../../components/NavLinkWithQuery/NavLinkWithQuery';

// import Airdrop from '../Airdrop/Airdrop';
import LinearUnlock from '../LinearUnlock/LinearUnlock';
import RewardsPool from '../RewardsPool/RewardsPool';

import MainContainer from '../../components/UI/MainContainer/MainContainer';
import Rewards from '../Rewards/Rewards';
import RedirectWithQuery from '../../components/RedirectWithQuery/RedirectWithQuery';
import styles from './Claim.module.scss';

const Claim = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const ROUTES = useMemo(
    () => [
      {
        name: t('Farm Vesting', 'Farm Vesting'),
        shortName: t('Farm Vesting'),
        path: '/unlock',
        component: LinearUnlock,
      },
      // {
      //   name: t('Airdrop', 'Airdrop'),
      //   shortName: t('Airdrop'),
      //   path: '/airdrop',
      //   component: Airdrop,
      // },
      {
        name: t('Rewards Plan', 'Rewards Plan'),
        shortName: t('Rewards Plan'),
        path: '/rewards',
        component: Rewards,
      },
      {
        name: t('Horizon Treasury', 'Horizon Treasury'),
        shortName: t('Treasury', 'Treasury'),
        path: '/treasury',
        component: RewardsPool,
      },
      // {
      //   name: t('Nautilus Gas Rewards', 'Nautilus Gas Rewards'),
      //   shortName: t('Nautilus Gas Rewards', 'Nautilus Gas Rewards'),
      //   path: '/gasShare',
      //   component: NautilusGasShare,
      // },
    ],
    [t],
  );
  return (
    <div className={styles.Claim}>
      <MainContainer className={styles.titleWrap}>
        <Switch>
          {ROUTES.map((item) => (
            <Route path={`${path}${item.path}`} exact key={item.shortName}>
              <h2>{item.name}</h2>
            </Route>
          ))}
          <RedirectWithQuery to={`${path}${ROUTES?.[0]?.path}`} />
        </Switch>
        <div className={styles.navWrap}>
          {ROUTES.map((item) => (
            <NavLinkWithQuery
              key={item.shortName}
              to={`${path}${item.path}`}
              className={(isActive) => classNames(styles.navItem, { [styles.active]: isActive })}>
              {item.shortName}
            </NavLinkWithQuery>
          ))}
        </div>
      </MainContainer>
      <Switch>
        {ROUTES.map((item) => {
          const Component = item.component;
          return (
            <Route path={`${path}${item.path}`} exact key={item.shortName}>
              <Component />
            </Route>
          );
        })}
      </Switch>
    </div>
  );
};

export default Claim;
