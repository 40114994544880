import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TitleCombo.module.scss';

const TitleCombo = (props) => {
  const { className, children, small, white } = props;
  return (
    <div
      className={classNames(
        styles.TitleCombo,
        className,
        { [styles.small]: small },
        { [styles.white]: white },
      )}>
      {children}
    </div>
  );
};

TitleCombo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  small: PropTypes.bool,
  white: PropTypes.bool,
};
TitleCombo.defaultProps = {
  className: '',
  children: null,
  small: false,
  white: false,
};

export default TitleCombo;
