import { BigNumber, ethers } from 'ethers';
import { useEffect, useCallback } from 'react';
import { useWallet } from '../store/wallet-context';
import { safeBigNumberToNumber } from '../utils/utils';
import { useContract, useManagerContract } from './useContract';
import { useUpdate } from './useUpdate';

export const usePhase1Config = () => {
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();

  const decodePhase1Config = useCallback(
    (config) => ({
      phase1StartTimestamp: safeBigNumberToNumber(config.phase1StartTimestamp),
      phase1EndTimestamp: safeBigNumberToNumber(config.phase1EndTimestamp),
      phase1LockDuration: safeBigNumberToNumber(config.phase1LockDuration),
      phase1TokenIdToSell: safeBigNumberToNumber(config.phase1TokenIdToSell),
      phase1SellPrice: Number(ethers.utils.formatUnits(config.phase1SellPrice)),
      phase1ZbcLockAmount: Number(ethers.utils.formatUnits(config.phase1ZbcLockAmount, 9)),
      phase1ZPointPerZepoch: Number(ethers.utils.formatEther(config.phase1ZPointPerZepoch)),
      phase1ZbcTotalLocked: Number(ethers.utils.formatUnits(config.phase1ZbcTotalLocked, 9)),
    }),
    [],
  );
  const { state, read } = useContract(managerContract, 'phase1Config', decodePhase1Config, {});

  useEffect(() => {
    const fetchData = () => {
      if (!managerContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, managerContract]);
  return state;
};

export const usePhase2Config = () => {
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();

  const decodePhase2Config = useCallback(
    (config) => ({
      phase2StartTimestamp: safeBigNumberToNumber(config.phase2StartTimestamp),
      phase2EndTimestamp: safeBigNumberToNumber(config.phase2EndTimestamp),
      phase2TokenIdToSell: safeBigNumberToNumber(config.phase2TokenIdToSell),
      phase2SellBasePrice: Number(ethers.utils.formatEther(config.phase2SellBasePrice)),
    }),
    [],
  );
  const { state, read } = useContract(managerContract, 'phase2Config', decodePhase2Config, {});

  useEffect(() => {
    const fetchData = () => {
      if (!managerContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, managerContract]);
  return state;
};

// export const useLockZBC = () => {
//   const managerContract = useManagerContract();
//   const { account } = useWallet();

//   const { state, write } = useContract(managerContract, 'lockZBC');

//   const lockZBC = (amount, inviterCode) => {
//     if (!(managerContract && amount && account)) {
//       return;
//     }
//     write([amount, ethers.utils.formatBytes32String(inviterCode)]);
//   };

//   return { state, lockZBC };
// };
export const useLockZBCOrPayUsd = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'lockZBCOrPayUsd');

  const lockZBCOrPayUsd = async (amount, inviterCode, payment) => {
    if (!(managerContract && amount && account)) {
      return;
    }
    await write([amount, ethers.utils.formatBytes32String(inviterCode), payment]);
  };

  return { state, lockZBCOrPayUsd };
};

export const useUnlockZBC = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'unlockZBC');

  const unlockZBC = async (tokenIds) => {
    if (!(managerContract && tokenIds?.length && account)) {
      return;
    }
    await write([tokenIds]);
  };

  return { state, unlockZBC };
};

export const useBuy = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'buy');

  // adjust sequence of params to match useLockZBCOrPayUsd(amount, inviterCode, payment)
  const buy = async (amount, inviterCode, payment, price) => {
    if (!(managerContract && amount && account && Object.keys(price).length)) {
      return;
    }
    await write([payment, amount, ethers.utils.formatBytes32String(inviterCode), price]);
  };

  return { state, buy };
};

// use original data
// export const useGetPurchaseInfo = (accountAddress = null) => {
//   const managerContract = useManagerContract();
//   const { updateByTimer } = useUpdate();
//   const { state, read } = useContract(managerContract, 'getPurchaseInfo', null, {});
//   const { account } = useWallet();
//   useEffect(() => {
//     const fetchData = () => {
//       if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
//         return;
//       }
//       read([accountAddress || account]);
//     };
//     fetchData();
//   }, [updateByTimer, read, managerContract, account, accountAddress]);
//   return state;
// };

export const useEarnedZPoint = (accountAddress = null) => {
  const formatEther = useCallback((number) => Number(ethers.utils.formatEther(number)), []);
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();
  const { state, read } = useContract(managerContract, 'earnedZPoint', formatEther, 0);
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
        return;
      }
      read([accountAddress || account]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account, accountAddress]);
  return state;
};

export const useStakeZPointRewardPerDay = () => {
  const formatEther = useCallback((number) => Number(ethers.utils.formatEther(number)), []);
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();
  const { state, read } = useContract(managerContract, 'stakeZPointRewardPerDay', formatEther, 0);
  useEffect(() => {
    const fetchData = () => {
      if (!managerContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account]);
  return state;
};

export const useClaimZPoint = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'claimZPoint');

  const claimZPoint = () => {
    if (!(managerContract && account)) {
      return;
    }
    write();
  };

  return { state, claimZPoint };
};

export const useStake = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();
  const { state, write } = useContract(managerContract, 'stake');

  const stake = (tokenIds, invitationCode) => {
    if (!(managerContract && account && tokenIds.length)) {
      return;
    }
    write([tokenIds, ethers.utils.formatBytes32String(invitationCode)]);
  };

  return { state, stake };
};

export const useUnstake = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();
  const { state, write } = useContract(managerContract, 'unstake');

  const unstake = (tokenIds) => {
    if (!(managerContract && account && tokenIds.length)) {
      return;
    }
    write([tokenIds]);
  };

  return { state, unstake };
};

export const useCalcPhase2Cost = (amount) => {
  const formatEther = useCallback((number) => Number(ethers.utils.formatEther(number?.[0])), []);
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(managerContract, 'calcPhase2Cost', formatEther, 0);
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && amount)) {
        return;
      }
      read([amount]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, amount]);
  return state;
};

export const useClaimViaVault = () => {
  const managerContract = useManagerContract();

  const { state, write } = useContract(managerContract, 'claimViaVault');

  const claimViaVault = async (claimData) => {
    if (!(managerContract && claimData && Object.keys(claimData))) {
      return;
    }
    await write([
      claimData.address,
      claimData.token,
      BigNumber.from(claimData.amount),
      claimData.reason,
      BigNumber.from(claimData.timestamp),
      claimData.v,
      claimData.r,
      claimData.s,
    ]);
  };

  return { state, claimViaVault };
};

// use original data type

export const useJudgeUnlockInfo = (accountAddress = null) => {
  // const decodeJudgeUnlockInfo = useCallback(
  //   (config) => ({
  //     level: Number(config.level),
  //     apy: Number(config.apy),
  //     nominalUsdBalance: Number(ethers.utils.formatEther(config.nominalUsdBalance)),
  //   }),
  //   [],
  // );
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(managerContract, 'judgeUnlockInfo', null, {});
  const { account } = useWallet();
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
        return;
      }
      read([accountAddress || account]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account, accountAddress]);
  return state;
};

export const useCalcClaim = (accountAddress = null) => {
  // const formatUnits = useCallback((number) => Number(ethers.utils.formatUnits(number, 9)), []);
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(managerContract, 'calcClaim', null, ethers.constants.Zero);
  const { account } = useWallet();
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
        return;
      }
      read([accountAddress || account]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account, accountAddress]);
  return state;
};

export const useGetUnlockInfo = (accountAddress = null) => {
  // const decodeGetUnlockInfo = useCallback(
  //   (config) => ({
  //     // =========Won't Modify in updateRecord
  //     // 0 for un-init-ed
  //     level: Number(config.level),
  //     apy: Number(config.apy),
  //     lastNominalUsdAmount: Number(ethers.utils.formatEther(config.lastNominalUsdAmount)),

  //     // =========modify in updateRecord
  //     lastUpdatedTimestamp: Number(config.lastUpdatedTimestamp),
  //     unlockScanOffset: Number(config.unlockScanOffset),

  //     zbcClaimable: Number(ethers.utils.formatUnits(config.zbcClaimable, 9)),
  //     zbcClaimedCumulative: Number(ethers.utils.formatUnits(config.zbcClaimedCumulative, 9)),
  //     // only for log
  //     lastClaimTimestamp: Number(config.lastClaimTimestamp),
  //   }),
  //   [],
  // );
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(managerContract, 'getUnlockInfo', null, {});
  const { account } = useWallet();
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
        return;
      }
      read([accountAddress || account]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account, accountAddress]);
  return state;
};

export const useGetPurchaseInfo = (accountAddress = null) => {
  // const decodeGetPurchaseInfo = useCallback(
  //   (config) => ({
  //     // bought amount
  //     zepochAmount: Number(config.zepochAmount),
  //     // phase 1 + phase 2, usdAmount
  //     usdPayed: Number(ethers.utils.formatEther(config.usdPayed)),
  //     // phase 1, zbcAmount
  //     zbcPayed: Number(ethers.utils.formatUnits(config.zbcPayed)),

  //     // invitee's bought amount
  //     zepochInviteeAmount: Number(config.zepochInviteeAmount),
  //   }),
  //   [],
  // );
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(managerContract, 'getPurchaseInfo', null, {});
  const { account } = useWallet();
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
        return;
      }
      read([accountAddress || account]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account, accountAddress]);
  return state;
};

export const useNeedToSync = (accountAddress = null) => {
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(managerContract, 'needToSync', null, false);
  const { account } = useWallet();
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && (ethers.utils.isAddress(accountAddress) || account))) {
        return;
      }
      read([accountAddress || account]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, account, accountAddress]);
  return state;
};

export const useActivate = () => {
  const managerContract = useManagerContract();

  const { state, write } = useContract(managerContract, 'activate');

  const activate = async (price) => {
    if (!(managerContract && price && Object.keys(price).length)) {
      return;
    }
    await write([price]);
  };

  return { state, activate };
};

export const useSyncUnlock = () => {
  const managerContract = useManagerContract();

  const { state, write } = useContract(managerContract, 'syncUnlock');

  const syncUnlock = async (who, price) => {
    if (!(managerContract && who && price && Object.keys(price).length)) {
      return;
    }
    await write([who, price]);
  };

  return { state, syncUnlock };
};

export const useClaim = () => {
  const managerContract = useManagerContract();

  const { state, write } = useContract(managerContract, 'claim');

  const claim = async () => {
    if (!managerContract) {
      return;
    }
    await write();
  };

  return { state, claim };
};

export const useExchange = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'exchange');

  const exchange = async (amount) => {
    if (!(managerContract && account)) {
      return;
    }
    await write([amount]);
  };

  return { state, exchange };
};

// test only
export const useTestBuyZbc = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'testBuyZbc');

  const testBuyZbc = async (amount, inviterCode) => {
    if (!(managerContract && account)) {
      return;
    }
    await write([amount, ethers.utils.formatBytes32String(inviterCode)]);
  };

  return { state, testBuyZbc };
};

export const useTestBuyUsd = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'testBuyUsd');

  const testBuyUsd = async (amount, inviterCode) => {
    if (!(managerContract && account)) {
      return;
    }
    await write([amount, ethers.utils.formatBytes32String(inviterCode)]);
  };

  return { state, testBuyUsd };
};

export const useTestRegister = () => {
  const managerContract = useManagerContract();
  const { account } = useWallet();

  const { state, write } = useContract(managerContract, 'testRegister');

  const testRegister = async (amount, inviterCode) => {
    if (!(managerContract && account && inviterCode)) {
      return;
    }
    await write([amount, inviterCode]);
  };

  return { state, testRegister };
};

export const useZbcSupplyForUnlock = () => {
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();
  const { state, read } = useContract(
    managerContract,
    'zbcSupplyForUnlock',
    null,
    ethers.constants.Zero,
  );
  useEffect(() => {
    const fetchData = () => {
      if (!managerContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, managerContract]);
  return state;
};

export const useClaimUsdtAndBusdViaVault = () => {
  const managerContract = useManagerContract();

  const { state, write } = useContract(managerContract, 'claimUsdtAndBusdViaVault');

  const claimUsdtAndBusdViaVault = async (claimData) => {
    if (!(managerContract && claimData && Object.keys(claimData))) {
      return;
    }

    await write([
      claimData.who,
      claimData.usdtAmount,
      claimData.busdAmount,
      claimData.reason,
      claimData.claimedTimestamp,
      claimData.v,
      claimData.r,
      claimData.s,
    ]);
  };

  return { state, claimUsdtAndBusdViaVault };
};

export const useGetPhase2BuyViaZbcDiscountInMillion = () => {
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();

  const { state, read } = useContract(
    managerContract,
    'getPhase2BuyViaZbcDiscountInMillion',
    null,
    ethers.constants.Zero,
  );

  useEffect(() => {
    const fetchData = () => {
      if (!managerContract) {
        return;
      }
      read();
    };
    fetchData();
  }, [updateByTimer, read, managerContract]);
  return state;
};

export const useCalcPhase2CostZbc = (amount, price) => {
  const managerContract = useManagerContract();
  const { updateByTimer } = useUpdate();

  const { state, read } = useContract(
    managerContract,
    'calcPhase2CostZbc',
    null,
    ethers.constants.Zero,
  );
  useEffect(() => {
    const fetchData = () => {
      if (!(managerContract && amount && Object.keys(price).length)) {
        return;
      }
      read([amount, price]);
    };
    fetchData();
  }, [updateByTimer, read, managerContract, amount, price]);
  return state;
};
