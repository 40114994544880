import { constants, utils } from 'ethers';
import { useEffect } from 'react';
import { useWallet } from '../store/wallet-context';
import { useContract, useTokenPoolContract } from './useContract';
import { useUpdate } from './useUpdate';
import { useTokenBalance, useTokenTotalSupply } from './useToken';
import { DEV_ADDRESSES } from '../constants/addresses';
import { YEAR } from '../utils/utils';

export const useTokenPool = () => {
  const tokenPoolContract = useTokenPoolContract();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();

  const { state: configState, read: readConfig } = useContract(
    tokenPoolContract,
    'getConfig',
    null,
    {},
  );
  const { state: rewardPerTokenState, read: readRewardPerToken } = useContract(
    tokenPoolContract,
    'rewardPerToken',
    null,
    constants.Zero,
  );

  const { state: stakedAccountLengthState, read: readStakedAccountLength } = useContract(
    tokenPoolContract,
    'stakedAccountLength',
    null,
    constants.Zero,
  );

  // depends on account
  const { state: recordsState, read: readRecords } = useContract(
    tokenPoolContract,
    'records',
    null,
    {},
  );
  const { state: earnedState, read: readEarned } = useContract(
    tokenPoolContract,
    'earned',
    null,
    constants.Zero,
  );

  useEffect(() => {
    const fetchData = () => {
      if (!tokenPoolContract) {
        return;
      }
      readConfig();
      readRewardPerToken();
      readStakedAccountLength();
    };
    fetchData();
  }, [tokenPoolContract, readConfig, readRewardPerToken, readStakedAccountLength, updateByTimer]);

  useEffect(() => {
    const fetchData = () => {
      if (!(tokenPoolContract && utils.isAddress(account))) {
        return;
      }
      readRecords([account]);
      readEarned([account]);
    };
    fetchData();
  }, [tokenPoolContract, account, readRecords, readEarned, updateByTimer]);

  const { sourceAddress } = configState.data;
  const { data: usdtBalanceInSource } = useTokenBalance(DEV_ADDRESSES.USDT, 18, sourceAddress, true);
  const { data: zbcBalanceInSource } = useTokenBalance(DEV_ADDRESSES.ZBC, 9, sourceAddress, true);
  const { data: sourceTotalSupply} = useTokenTotalSupply(sourceAddress, 18, true);
  const sourcePrice = usdtBalanceInSource * 2 / sourceTotalSupply;
  const zbcPrice = usdtBalanceInSource / zbcBalanceInSource / 1000000000;
  const { data: sourceBalance } = useTokenBalance(sourceAddress, 18, tokenPoolContract?.address || null, true);
  const totalStakedValue = sourcePrice * sourceBalance;
  const totalRewardValuePerYear = configState.data.rewardRate * YEAR * zbcPrice;
  const APR = (totalRewardValuePerYear / totalStakedValue * 100).toFixed(2);

  return {
    configState,
    recordsState,
    rewardPerTokenState,
    earnedState,
    stakedAccountLengthState,
    APR,
  };
};

export const useStake = () => {
  const tokenPoolContract = useTokenPoolContract();

  const { account } = useWallet();

  const { state, write } = useContract(tokenPoolContract, 'stake');

  const stake = async (amount) => {
    if (!(tokenPoolContract && account && amount)) {
      return;
    }
    await write([amount]);
  };

  return { state, stake };
};

export const useWithdraw = () => {
  const tokenPoolContract = useTokenPoolContract();

  const { account } = useWallet();

  const { state, write } = useContract(tokenPoolContract, 'withdraw');

  const withdraw = async (amount) => {
    if (!(tokenPoolContract && account && amount)) {
      return;
    }
    await write([amount]);
  };

  return { state, withdraw };
};

export const useGetReward = () => {
  const tokenPoolContract = useTokenPoolContract();

  const { account } = useWallet();

  const { state, write } = useContract(tokenPoolContract, 'getReward');

  const getReward = async () => {
    if (!(tokenPoolContract && account)) {
      return;
    }
    await write();
  };

  return { state, getReward };
};

export const useUpdateRewardRate = () => {
  const tokenPoolContract = useTokenPoolContract();

  const { account } = useWallet();

  const { state, write } = useContract(tokenPoolContract, 'updateRewardRate');

  const updateRewardRate = async (rewardPerDay, endTime) => {
    if (!(tokenPoolContract && account && rewardPerDay && endTime !== null)) {
      return;
    }
    await write([rewardPerDay, endTime]);
  };

  return { state, updateRewardRate };
};
