import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RedirectWithQuery from '../../components/RedirectWithQuery/RedirectWithQuery';
import { AIRDROPS } from '../AirdropNew/AirdropNew';

// relative path used

const AirdropDetail = () => {
  const match = useRouteMatch();
  const parentPath = match.url.substring(0, match.url.lastIndexOf('/'));
  return (
    <Switch>
      {AIRDROPS.map((item) => (
        <Route
          key={item.path}
          path={`${parentPath}${item.path}`}
          exact={item.exact}
          component={item.component}
        />
      ))}
      <RedirectWithQuery to={parentPath} />
    </Switch>
  );
};

export default AirdropDetail;
