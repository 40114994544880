import React from 'react';
// import { Col, Row } from 'antd';
// import { useTranslation } from 'react-i18next';
// import { formatEther } from 'ethers/lib/utils';
// import StatsItem from '../../components/StatsItem/StatsItem';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
// import { useNFTRecords } from '../../hooks/useNFT';
// import { formatNumber } from '../../utils/utils';
// import { useGetPurchaseInfo, useJudgeUnlockInfo } from '../../hooks/useManager';
import RewardsPlan from '../../components/Rewards/RewardsPlan/RewardsPlan';
import styles from './Rewards.module.scss';

// const DESC = [
//   { id: 0, percent: '50%', count: 1 },
//   { id: 1, percent: '100%', count: 5 },
//   // { id: 2, percent: '150%', count: 15 },
//   // { id: 3, percent: '200%', count: 30 },
//   // { id: 4, percent: '250%', count: 50 },
// ];

// const STATS = [
//   {
//     id: 0,
//     title: 'Zepoch Value',
//     img: '/static/images/About/statsIconPrice.svg',
//     value: '-',
//   },
//   {
//     id: 1,
//     title: 'My Zepoch',
//     img: '/static/images/About/statsIconNode.svg',
//     value: '-',
//   },
//   {
//     id: 2,
//     title: 'Zepoch Referrals',
//     img: '/static/images/About/statsIconPoints.svg',
//     value: '-',
//   },
// ];

const Rewards = () => (
  // const [stats, setStats] = useState(STATS);
  // const { t } = useTranslation();
  // const { data: estimatedUnlockInfo } = useJudgeUnlockInfo();
  // const { data: nftRecords } = useNFTRecords();
  // const { zepochNodeRecords } = nftRecords;
  // const { data: purchaseInfo } = useGetPurchaseInfo();

  // need to match STATS
  // const statsData = useMemo(
  //   () => [
  //     formatNumber(formatEther(estimatedUnlockInfo.nominalUsdBalance ?? 0), '$0,0.[00]'),
  //     formatNumber(zepochNodeRecords?.length),
  //     formatNumber(purchaseInfo.zepochInviteeAmount?.toNumber()),
  //   ],
  //   [estimatedUnlockInfo.nominalUsdBalance, zepochNodeRecords, purchaseInfo.zepochInviteeAmount],
  // );
  // useEffect(() => {
  //   setStats((prev) => prev.map((item, index) => ({ ...item, value: statsData[index] })));
  // }, [statsData]);

  <div className={styles.Rewards}>
    {/* <div
        className={styles.banner}
        style={{ backgroundImage: 'url(/static/images/Rewards/bannerBg.png)' }}>
        <MainContainer className={styles.bannerContainer}>
          <div className={styles.textWrap}>
            <h2>{t('ZEBEC REWARDS PLAN', 'ZEBEC REWARDS PLAN')}</h2>
            <h1>
              {t(
                '100 Million ZBC Giveaway for Zepoch Supporters',
                '100 Million $ZBC Giveaway for Zepoch Node NFT Supporters',
              )}
            </h1>
            <h1>{t('Hold and Earn up to 100% APY', 'Hold and Earn up to 100% APY')}</h1>
          </div>
          <img src="/static/images/Rewards/bills.png" alt="bills" />
        </MainContainer>
      </div> */}
    <MainContainer className={styles.mainContent}>
      {/* <div className={styles.statsWrap}>
          <Row gutter={[16, 16]}>
            {stats.map((item) => (
              <Col span={24} md={8} key={item.id}>
                <StatsItem
                  img={item.img}
                  value={item.value}
                  title={item.title}
                  className={styles.statsItem}
                />
              </Col>
            ))}
          </Row>
        </div> */}

      <RewardsPlan />

      {/* <Row className={styles.descWrap} gutter={[16, 16]} justify="center">
          {DESC.map((item) => (
            <Col key={item.id} span={12} md={8}>
              <div className={styles.descItem}>
                <h2 className={styles.percentage}>{item.percent}</h2>
                <div className={styles.descCountWrap}>
                  <h2>{t('Owned Zepoch', 'Owned Zepoch')}</h2>
                  <h2>+</h2>
                  <h2>{t('Referred Zepoch', 'Referred Zepoch')}</h2>
                  <h2>&gt;=</h2>
                  <div className={styles.count}>{item.count}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row> */}
    </MainContainer>
  </div>
);

export default Rewards;
