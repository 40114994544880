import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const HashLinkWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HashLink to={to + search} {...props}>
      {children}
    </HashLink>
  );
};

HashLinkWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default HashLinkWithQuery;
