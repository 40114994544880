import { WEBSITE_URL } from './misc';

export const WALLET_TYPE = {
  METAMASK: 'metamask',
  TRUST: 'trust',
  COINBASE: 'coinbase',
  BINANCE: 'binance',
  TOKENPOCKET: 'tokenPocket',
  SAFEPAL: 'safepal',
  IMTOKEN: 'imtoken',
  COIN98: 'coin98',
  ONTO: 'onto',
  CLOVER: 'clover',
  WALLETCONNECT: 'walletConnect',
};

export const WALLET_INFO = {
  [WALLET_TYPE.METAMASK]: {
    name: 'MetaMask',
    link: `https://metamask.app.link/dapp/${WEBSITE_URL}`,
  },
  [WALLET_TYPE.TRUST]: {
    name: 'Trust',
    link: `https://link.trustwallet.com/open_url?coin_id=60&url=${WEBSITE_URL}`,
  },
  [WALLET_TYPE.COINBASE]: {
    name: 'Coinbase',
    link: `https://go.cb-w.com/dapp?cb_url=${WEBSITE_URL}`,
  },
  [WALLET_TYPE.BINANCE]: {
    name: 'Binance',
    link: `https://link.trustwallet.com/open_url?coin_id=60&url=${WEBSITE_URL}`,
  },
  [WALLET_TYPE.TOKENPOCKET]: {
    name: 'TokenPocket',
    link: 'https://www.tokenpocket.pro/',
  },
  [WALLET_TYPE.SAFEPAL]: {
    name: 'SafePal',
    link: 'https://safepal.io/',
  },
  [WALLET_TYPE.IMTOKEN]: {
    name: 'imToken',
    link: `imtokenv2://navigate/DappView?url=${WEBSITE_URL}`,
  },
  [WALLET_TYPE.COIN98]: {
    name: 'Coin98',
    link: 'https://coin98.com/',
  },
  [WALLET_TYPE.ONTO]: {
    name: 'ONTO',
    link: 'https://onto.app/',
  },
  [WALLET_TYPE.CLOVER]: {
    name: 'Clover',
    link: 'https://clv.org/',
  },
  [WALLET_TYPE.WALLETCONNECT]: {
    name: 'WalletConnect',
    link: 'https://walletconnect.com/',
  },
};

export const SUPPORTTED_WALLET = [
  WALLET_TYPE.METAMASK,
  WALLET_TYPE.TRUST,
  WALLET_TYPE.IMTOKEN,
  WALLET_TYPE.TOKENPOCKET,
  WALLET_TYPE.WALLETCONNECT,
];
