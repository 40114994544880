import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGeolocation } from '../../hooks/useGeolocation';
import CustomModal from '../UI/CustomModal/CustomModal';
import styles from './KYCModal.module.scss';
import CustomButton from '../UI/CustomButton/CustomButton';
import { BLOCKED_COUNTRY, BLOCKED_COUNTRY_BY_IP } from '../../constants/misc';

const KYCModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const geolocation = useGeolocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (BLOCKED_COUNTRY_BY_IP.includes(geolocation.country)) {
      setModalVisible(true);
    }
  }, [geolocation]);

  return (
    <CustomModal
      open={modalVisible}
      onCancel={() => {
        setModalVisible(false);
      }}>
      <div className={styles.KYCModalContent}>
        <h2>{t('KYC title', 'Zebec Service Not Available')}</h2>
        <p>
          {t(
            'KYC desc',
            'For regulatory reasons, Zebec does not offer service in the following jurisdictions:',
          )}
        </p>
        <List
          style={{ width: '100%' }}
          size="small"
          dataSource={BLOCKED_COUNTRY}
          renderItem={(item) => (
            <List.Item
              style={{
                justifyContent: 'center',
                fontWeight: '500',
                fontSize: '14px',
                color: 'rgba(0,0,0,0.8)',
                padding: '3px',
              }}>
              {item}
            </List.Item>
          )}
        />
        <CustomButton
          onClick={() => {
            setModalVisible(false);
          }}>
          {t('KYC not apply', 'This Does Not Apply to Me')}
        </CustomButton>
      </div>
    </CustomModal>
  );
};

export default KYCModal;
