import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const RedirectWithQuery = ({ to, ...props }) => {
  const { search } = useLocation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Redirect to={{ pathname: to, search }} {...props} />
  );
};

RedirectWithQuery.propTypes = {
  to: PropTypes.string.isRequired,
};

export default RedirectWithQuery;
