import React, { useEffect, useMemo } from 'react';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { constants } from 'ethers';
import { QuestionCircleFilled } from '@ant-design/icons';
import { message, Statistic } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { formatNumber, checkTime } from '../../utils/utils';
import { useGetReward, useTokenPool } from '../../hooks/useTokenPool';
import { LP_TOKEN_DECIMAL } from '../../constants/addresses';
import { useTokenBalance } from '../../hooks/useToken';

import MainContainer from '../../components/UI/MainContainer/MainContainer';
import Card from '../../components/UI/Card/Card';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import InfoItem from '../../components/TokenPool/InfoItem/InfoItem';
import CustomTooltip from '../../components/UI/CustomTooltip/CustomTooltip';

import styles from './TokenPool.module.scss';
import { useWallet } from '../../store/wallet-context';

const { Countdown } = Statistic;

const TokenPool = () => {
  const {
    configState: { data: config },
    recordsState: { data: record },
    earnedState: { data: earned },
    APR,
  } = useTokenPool();
  // nativeBalance
  const { data: nativeBalance } = useTokenBalance(constants.AddressZero, 18, null, false);
  const { state: getRewardState, getReward } = useGetReward();
  const { account } = useWallet();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = () => {};
    fetchData();
  }, [APR]);

  const infoList = useMemo(
    () => [
      { title: t('APY', 'APY'), value: `${APR}%` },
      {
        title: t('Total LP Staked', 'Total LP Staked'),
        value: formatNumber(
          formatUnits(config.totalSupplySource || constants.Zero, LP_TOKEN_DECIMAL),
        ),
      },
      {
        title: t('Your LP Staked', 'Your LP Staked'),
        value: formatNumber(
          formatUnits(record.stakedSourceAmount || constants.Zero, LP_TOKEN_DECIMAL),
        ),
      },
      {
        title: (
          <span>
            {t('Claimable Rewards', 'Claimable Rewards')}{' '}
            <CustomTooltip
              title={t(
                'farm-claimable-rewards-tooltip',
                'The rewards will be linearly unlocked in 180 days',
              )}>
              <QuestionCircleFilled style={{ color: '#59d89d', fontSize: '16px' }} />
            </CustomTooltip>
          </span>
        ),
        value: `${formatNumber(formatUnits(earned, 9))} ZBC`,
      },
    ],
    [config.totalSupplySource, earned, record.stakedSourceAmount, t, APR],
  );

  const handleClaim = async () => {
    console.log('cccc');
    if (getRewardState.isLoading) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }

    if (earned.isZero()) {
      message.info(t('Nothing to claim', 'Nothing to claim'));

      return;
    }
    try {
      await getReward();
    } catch (e) {
      console.log('get reward failed', e);
    }
  };

  return (
    <MainContainer className={styles.TokenPool}>
      <Card className={styles.poolCard}>
        <div className={styles.cardHeader}>
          <img
            className={styles.tokenLogo}
            src="/static/images/TokenPool/zbc-usdt.png"
            alt="tokens"
          />
          <div className={styles.title}>
            <h2>ZBC-USDT LP</h2>
            <a
              href="https://pancakeswap.finance/add/0x37a56cdcD83Dce2868f721De58cB3830C44C6303/0x55d398326f99059fF775485246999027B3197955?step=1"
              target="_blank"
              rel="noopener noreferrer">
              <img src="/static/images/TokenPool/outsideLlink.svg" alt="link" />{' '}
              {t('Get ZBCUSDT LP', 'Get ZBC-USDT LP')}
            </a>
          </div>
        </div>
        <hr />
        <div className={styles.infoWrap}>
          {infoList.map((item) => (
            <InfoItem title={item.title} value={item.value} key={item.title} />
          ))}
        </div>
        {config.startTime && (
          <div className={styles.actionWrap}>
            {checkTime(config.startTime.toNumber() * 1000) ? (
              <>
                <CustomButton
                  className={styles.button}
                  secondary
                  onClick={handleClaim}
                  loading={getRewardState.isLoading}>
                  {t('Claim', 'Claim')}
                </CustomButton>
                <CustomButton className={styles.button} to="/farm/stake">
                  {t('Stake', 'Stake')}
                </CustomButton>
              </>
            ) : (
              <CustomButton className={classNames(styles.button, styles.countdown)} disabled>
                {t('Start in', 'Start in')}
                <Countdown
                  value={config.startTime.toNumber() * 1000}
                  valueStyle={{
                    color: 'black',
                    opacity: '0.5',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '2px',
                  }}
                />
              </CustomButton>
            )}
          </div>
        )}
      </Card>
    </MainContainer>
  );
};

export default TokenPool;
