import { useEffect, useReducer } from 'react';
import { NFT_TYPE, PHASE_1_NFT_AMOUNT } from '../constants/misc';
import { useWallet } from '../store/wallet-context';
import { checkTime, FETCH_STATE, readContractReducer } from '../utils/utils';
import {
  useContract,
  useMulticallContract,
  useThemisGavelContract,
  useZepochNodeContract,
} from './useContract';
import { useIsMounted } from './useIsMounted';
import { useUpdate } from './useUpdate';
import { multicallHelper } from '../utils/multicall';
import ZHManagerInterface from '../abis/ZHManagerInterface.json';
import HERC721IMInterface from '../abis/HERC721IMInterface.json';
import { CONTRACT_NAME } from '../constants/addresses';
import { useNameservice } from '../store/nameservice-context';
import { usePhase1Config } from './useManager';

const decodePhase1ZbcUserLocked = (record, phase1LockDuration) => {
  if (record.unlocked || record.timestamp.toNumber() === 0) {
    return {
      unlocked: record.unlocked,
    };
  }
  return {
    unlockTimestamp: record.timestamp.toNumber() + phase1LockDuration,
    unlockable: checkTime((record.timestamp.toNumber() + phase1LockDuration) * 1000),
    unlocked: record.unlocked,
  };
};

export const useNFTRecords = () => {
  const [state, dispatch] = useReducer(readContractReducer, {
    isLoading: false,
    isError: false,
    data: {},
  });

  // contracts
  const zepochNodeContract = useZepochNodeContract();
  const themisGavelContract = useThemisGavelContract();
  const multicallContract = useMulticallContract();

  const { [CONTRACT_NAME.Manager]: managerAddress } = useNameservice();
  const { data: phase1Config } = usePhase1Config();
  const { phase1LockDuration } = phase1Config;
  const isMounted = useIsMounted();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!(zepochNodeContract && themisGavelContract && account && managerAddress)) {
          return;
        }
        dispatch({ type: FETCH_STATE.INIT });

        // get nft records

        const [zepochBalance, themisBalance] = await Promise.all([
          zepochNodeContract.balanceOf(account),
          themisGavelContract.balanceOf(account),
        ]);

        const zepochIdsCall = [...Array(zepochBalance.toNumber()).keys()].map((item) => [
          zepochNodeContract.address,
          'tokenOfOwnerByIndex',
          [account, item],
        ]);
        const themisIdsCall = [...Array(themisBalance.toNumber()).keys()].map((item) => [
          themisGavelContract.address,
          'tokenOfOwnerByIndex',
          [account, item],
        ]);

        const [zepochNodeIdsRes, themisGavelIdsRes] = await Promise.all([
          multicallHelper(multicallContract, HERC721IMInterface.abi, zepochIdsCall),
          multicallHelper(multicallContract, HERC721IMInterface.abi, themisIdsCall),
        ]);
        const zepochNodeIds = zepochNodeIdsRes.flat().map((item) => item.toNumber());
        const themisGavelIds = themisGavelIdsRes.flat().map((item) => item.toNumber());

        const phase1ZepochNodeIds = zepochNodeIds.filter(
          (tokenId) => tokenId <= PHASE_1_NFT_AMOUNT + 1,
        );
        const phase2ZepochNodeIds = zepochNodeIds.filter(
          (tokenId) => tokenId > PHASE_1_NFT_AMOUNT + 1,
        );

        const phase1ZbcUserLockedCalls = phase1ZepochNodeIds.map((item) => [
          managerAddress,
          'phase1ZbcUserLocked',
          [item],
        ]);

        const phase1ZbcUserLocked = (
          await multicallHelper(multicallContract, ZHManagerInterface.abi, phase1ZbcUserLockedCalls)
        ).map((item) => decodePhase1ZbcUserLocked(item, phase1LockDuration));

        const phase1ZepochNodeRecords = [];

        phase1ZepochNodeIds.forEach((tokenId, index) => {
          phase1ZepochNodeRecords.push({
            tokenId,
            phase: 1,
            nftType: NFT_TYPE.ZEPOCH,
            phase1ZbcUserLocked: phase1ZbcUserLocked[index],
          });
        });

        const phase2ZepochNodeRecords = phase2ZepochNodeIds.map((tokenId) => ({
          tokenId,
          phase: 2,
          nftType: NFT_TYPE.ZEPOCH,
        }));

        const zepochNodeRecords = phase1ZepochNodeRecords
          .concat(phase2ZepochNodeRecords)
          .sort((a, b) => a.tokenId - b.tokenId);

        const themisGavelRecords = themisGavelIds.map((tokenId) => ({
          tokenId,
          nftType: NFT_TYPE.THEMIS,
        }));

        const unlockableZepochNode = zepochNodeRecords.filter(
          (item) => item?.phase1ZbcUserLocked?.unlockable && !item?.phase1ZbcUserLocked?.unlocked,
        );
        if (isMounted.current) {
          dispatch({
            type: FETCH_STATE.SUCCESS,
            payload: {
              zepochNodeRecords,
              themisGavelRecords,
              unlockableZepochNode,
            },
          });
        }
      } catch (error) {
        console.log(error);
        if (isMounted.current) {
          dispatch({ type: FETCH_STATE.FAILURE });
        }
      }
    };
    fetch();
  }, [
    zepochNodeContract,
    themisGavelContract,
    isMounted,
    updateByTimer,
    account,
    managerAddress,
    multicallContract,
    phase1LockDuration,
  ]);

  return state;
};

export const useNftAllowance = () => {
  const { [CONTRACT_NAME.Manager]: managerAddress } = useNameservice();
  const zepochNodeContract = useZepochNodeContract();
  const themisGavelContract = useThemisGavelContract();
  const { updateByTimer } = useUpdate();
  const { account } = useWallet();
  const { state: zepochNodeAllowance, read: readZepochNodeAllowance } = useContract(
    zepochNodeContract,
    'isApprovedForAll',
    null,
    false,
  );
  const { state: themisGavelAllowance, read: readThemisGavelAllowance } = useContract(
    themisGavelContract,
    'isApprovedForAll',
    null,
    false,
  );

  useEffect(() => {
    const fetch = async () => {
      if (!(account && managerAddress && zepochNodeContract && themisGavelContract)) {
        return;
      }
      readZepochNodeAllowance([account, managerAddress]);
      readThemisGavelAllowance([account, managerAddress]);
    };
    fetch();
  }, [
    updateByTimer,
    account,
    managerAddress,
    zepochNodeContract,
    themisGavelContract,
    readZepochNodeAllowance,
    readThemisGavelAllowance,
  ]);

  return { zepochNodeAllowance, themisGavelAllowance };
};

export const useZepochNodeSetApprovalForAll = () => {
  const zepochNodeContract = useZepochNodeContract();
  const { [CONTRACT_NAME.Manager]: managerAddress } = useNameservice();
  const { state, write } = useContract(zepochNodeContract, 'setApprovalForAll');
  const { account } = useWallet();

  const setApprovalForAll = () => {
    console.log('handle setApprovalForAll');
    if (!(zepochNodeContract && managerAddress && account)) {
      return;
    }
    write([managerAddress, true]);
  };

  return { state, setApprovalForAll };
};
