import React, { useMemo } from 'react';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { constants } from 'ethers';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '../../utils/utils';
import { useLinearUnlock, useUnlock } from '../../hooks/useLinearUnlock';
import { useWallet } from '../../store/wallet-context';
import { useTokenBalance } from '../../hooks/useToken';

import Card from '../../components/UI/Card/Card';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import InfoItem from '../../components/TokenPool/InfoItem/InfoItem';

import styles from './LinearUnlock.module.scss';

const LinearUnlock = () => {
  const {
    lockedAmountState: { data: lockedAmount },
    unlockedAmountState: { data: unlockableAmount },
  } = useLinearUnlock();

  const { data: nativeBalance } = useTokenBalance(constants.AddressZero, 18, null, false);
  const { account } = useWallet();
  const { t } = useTranslation();

  const { unlock, state: unlockState } = useUnlock();

  const infoList = useMemo(
    () => [
      { title: t('Lock Duration', 'Lock Duration'), value: '180 Days' },
      { title: t('Unlock Cycle', 'Unlock Cycle'), value: '3 Days' },
      { title: t('Locked', 'Locked'), value: formatNumber(formatUnits(lockedAmount, 9)) },
      {
        title: t('Claimable', 'Claimable'),
        value: formatNumber(formatUnits(unlockableAmount, 9)),
      },
    ],
    [lockedAmount, unlockableAmount, t],
  );

  const handleUnlock = async () => {
    if (unlockState.isLoading) {
      return;
    }
    if (!account) {
      message.info(t('Please connect to wallet', 'Please connect to wallet'));
      return;
    }
    if (nativeBalance.lt(parseUnits('0.01'))) {
      message.info(
        t(
          "You don't have enough BNB for gas to complete the transaction",
          "You don't have enough BNB for gas to complete the transaction",
        ),
      );
      return;
    }

    if (unlockableAmount.isZero()) {
      message.info(t('Nothing to claim', 'Nothing to claim'));
      return;
    }
    try {
      await unlock();
    } catch (e) {
      console.log('unlock failed', e);
    }
  };

  return (
    <MainContainer className={styles.LinearUnlock}>
      <Card className={styles.poolCard}>
        <div className={styles.cardHeader}>
          <div className={styles.title}>
            <h2>ZBC {t('Farm Vesting')}</h2>
          </div>
          <img className={styles.tokenLogo} src="/static/images/Icons/zbc.png" alt="tokens" />
        </div>
        <hr />
        <div className={styles.infoWrap}>
          {infoList.map((item) => (
            <InfoItem title={item.title} value={item.value} key={item.title} />
          ))}
        </div>
        <div className={styles.actionWrap}>
          <CustomButton
            className={styles.button}
            secondary
            onClick={handleUnlock}
            loading={unlockState.isLoading}>
            {t('Claim')}
          </CustomButton>
        </div>
      </Card>
    </MainContainer>
  );
};

export default LinearUnlock;
