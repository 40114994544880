import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Col, Row } from 'antd';
import styles from './AirdropNew.module.scss';
import Tab from '../../components/UI/Tab/Tab';
import TabLabel from '../../components/UI/Tab/TabLabel';
import SquareCard from '../../components/Airdrop/SquareCard';
import MainContainer from '../../components/UI/MainContainer/MainContainer';
import AirdropClaimNew from './Pose/AirdropClaimNew';

const AIRDROP_STATUS = {
  UPCOMING: 'UPCOMING',
  ONGOING: 'ONGOING',
  ENDED: 'ENDED',
};
export const AIRDROPS = [
  {
    path: '/',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    component: null,
    exact: true,
    bgImg: '/static/images/AirdropNew/Coral/cardBgCoral.png',
    logoImg: '/static/images/AirdropNew/Coral/cardLogoCoral.png',
    title: 'CORAL',
    desc: 'Coral',
    status: AIRDROP_STATUS.ONGOING,
    url: 'https://horizon.nautchain.xyz/airdrop/coral',
  },
  {
    path: '/',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    component: null,
    exact: true,
    bgImg: '/static/images/AirdropNew/Solana/cardBgSolana.png',
    logoImg: '/static/images/AirdropNew/Solana/cardLogoSolana.png',
    title: 'SOLANA',
    desc: 'Solana',
    status: AIRDROP_STATUS.ONGOING,
    url: 'https://airdrop.horizon.zebec.io/',
  },
  {
    path: '/',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    component: null,
    exact: true,
    bgImg: '/static/images/AirdropNew/DBOE/cardBgDBOE.png',
    logoImg: '/static/images/AirdropNew/DBOE/cardLogoDBOE.png',
    title: 'DBOE',
    desc: 'DBOE',
    status: AIRDROP_STATUS.UPCOMING,
  },
  {
    path: '/',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    component: null,
    exact: true,
    bgImg: '/static/images/AirdropNew/Eclipse/cardBgEclipse.png',
    logoImg: '/static/images/AirdropNew/Eclipse/cardLogoEclipse.png',
    title: 'ECLIPSE',
    desc: 'Eclipse',
    status: AIRDROP_STATUS.UPCOMING,
  },
  {
    path: '/',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    component: null,
    exact: true,
    bgImg: '/static/images/AirdropNew/Hexlink/cardBgHexlink.png',
    logoImg: '/static/images/AirdropNew/Hexlink/cardLogoHexlink.png',
    title: 'HEXLINK',
    desc: 'Hexlink',
    status: AIRDROP_STATUS.UPCOMING,
  },
  {
    path: '/',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    component: null,
    exact: true,
    bgImg: '/static/images/AirdropNew/OriginX/cardBgOriginX.png',
    logoImg: '/static/images/AirdropNew/OriginX/cardLogoOriginX.png',
    title: 'ORIGINX',
    desc: 'OriginX',
    status: AIRDROP_STATUS.UPCOMING,
  },
  {
    path: '/pose',
    component: AirdropClaimNew,
    exact: true,
    bgImg: '/static/images/AirdropNew/Pose/cardBgPose.png',
    logoImg: '/static/images/AirdropNew/Pose/cardLogoPose.png',
    title: 'POSEISWAP',
    desc: 'POSEISWAP',
    status: AIRDROP_STATUS.ENDED,
  },
];

const Airdrop = () => {
  const [activeTab, setActiveTab] = React.useState('UPCOMING');
  const { t } = useTranslation();
  const handleTabChange = (tabValue) => {
    setActiveTab(tabValue);
  };

  const { path } = useRouteMatch();

  return (
    <div className={styles.Airdrop}>
      <div className={styles.banner}>
        <div className={styles.silkBelt} />
        <div className={styles.airdropImage} />
        <div className={styles.airdropText}>
          <div className={styles.airdropTextHorizon}>{t('Zepoch', 'Zepoch')}</div>
          <div className={styles.airdropTextAirdrop}>{t('Airdrop', 'Airdrop')}</div>
        </div>
      </div>
      <div className={styles.container}>
        <Tab onChange={handleTabChange}>
          <TabLabel tag={AIRDROP_STATUS.UPCOMING}>{t('UPCOMING', 'UPCOMING')}</TabLabel>
          <TabLabel tag={AIRDROP_STATUS.ONGOING}>{t('ONGOING', 'ONGOING')}</TabLabel>
          <TabLabel tag={AIRDROP_STATUS.ENDED}>{t('ENDED', 'ENDED')}</TabLabel>
        </Tab>
        <MainContainer className={styles.cardContainer}>
          <Row gutter={[16, 16]}>
            {AIRDROPS.filter((airdrop) => airdrop.status === activeTab).map((airdrop) => (
              <Col span={24} sm={12} md={8} lg={6} key={airdrop.title}>
                <SquareCard
                  name={airdrop.title}
                  bgImg={airdrop.bgImg}
                  logoImg={airdrop.logoImg}
                  path={`${path}${airdrop.path === '/' ? '' : airdrop.path}`}
                  url={airdrop.url}
                />
              </Col>
            ))}
          </Row>
        </MainContainer>
      </div>
    </div>
  );
};

export default Airdrop;
