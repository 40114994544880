import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { ProvideUpdate } from './hooks/useUpdate';
import { ProvideNameservice } from './store/nameservice-context';
import { ProvideWallet } from './store/wallet-context';
import { ProvideQuery } from './hooks/useQuery';

import './index.css';
import './i18n';
import { ProvideAirdropPose } from './store/airdrop-pose-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ProvideQuery>
        <ProvideWallet>
          <ProvideUpdate>
            <ProvideNameservice>
              <ProvideAirdropPose>
                <App />
              </ProvideAirdropPose>
            </ProvideNameservice>
          </ProvideUpdate>
        </ProvideWallet>
      </ProvideQuery>
    </HashRouter>
  </React.StrictMode>,
);
