import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './CustomButton.module.scss';
import HashLinkWithQuery from '../../HashLinkWithQuery/HashLinkWithQuery';

const CustomButton = (props) => {
  const { className, children, onClick, secondary, gray, loading, disabled, rounded, to, href } =
    props;

  const properties = {
    className: classNames(
      styles.CustomButton,
      className,
      { [styles.secondary]: secondary },
      { [styles.gray]: gray },
      { [styles.disabled]: disabled },
      { [styles.loading]: loading },
      { [styles.rounded]: rounded },
    ),
    onClick,
  };

  const content = (
    <>
      {children}
      {loading && !disabled && (
        <>
          &nbsp;
          <LoadingOutlined />
        </>
      )}
    </>
  );

  if (href) {
    return (
      // eslint-disable-next-line
      <a {...properties} target="_blank" rel="noopener noreferrer" href={href}>
        {content}
      </a>
    );
  }
  if (to) {
    return (
      // eslint-disable-next-line
      <HashLinkWithQuery {...properties} to={to}>
        {content}
      </HashLinkWithQuery>
    );
  }

  return (
    // eslint-disable-next-line
    <div {...properties}>{content}</div>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  gray: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
};

CustomButton.defaultProps = {
  className: '',
  children: null,
  onClick: () => {},
  secondary: false,
  gray: false,
  loading: false,
  disabled: false,
  rounded: false,
  to: '',
  href: '',
};

export default CustomButton;
